import { useStringFlagValue } from "@openfeature/react-sdk";
import { AI_ASSISTANT_NAME_FLAG_KEY } from "../../../HeaderV2/components/header-v2-icons/ai-assistant/header-v2-ai-assistant-icon-button.const";

export const AI_ASSISTANT_NAME_DEFAULT_VALUE = "MarIA";

export function useChatbotName() {
  return useStringFlagValue(AI_ASSISTANT_NAME_FLAG_KEY, AI_ASSISTANT_NAME_DEFAULT_VALUE, {
    updateOnContextChanged: true,
    suspend: true,
  });
}
