import { PropsWithChildren } from "react";
import BonusXLoading from "../../../components/BonusXLoading";
import { useClientConstants } from "../../../hooks/use-client-constants/use-client-constants";

export function ClientConstantsProvider({ children }: Readonly<PropsWithChildren<{}>>) {
  const { isLoading } = useClientConstants();

  if (isLoading) {
    return <BonusXLoading />;
  }

  return <>{children}</>;
}
