import { IConfigCatProviderCachedDatasource } from "./config-cat-provider-cached-datasource.interface";

export class ConfigCatProviderCachedDatasource implements IConfigCatProviderCachedDatasource {
  private cache: Record<string, unknown> = {};

  get<T>(key: string): T | undefined {
    const cachedValue = this.cache[key];

    const isCachedValueUndefinedOrNull = cachedValue === undefined || cachedValue === null;
    if (isCachedValueUndefinedOrNull) {
      return undefined;
    }

    return this.cache[key] as T;
  }

  set<T>(key: string, value: T): void {
    this.cache[key] = value;
  }

  has(key: string): boolean {
    return this.cache[key] !== undefined;
  }
}
