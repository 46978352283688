import { useAtomValue, useSetAtom } from "jotai";
import isString from "lodash/isString";
import { useHistory } from "react-router";
import { nextURLStore, nextURLStoreKey } from "../stores/nextURLStore";

export function useNextURL() {
  const setNextURL = useSetNextURL();
  const { nextURL } = useAtomValue(nextURLStore);
  const history = useHistory();

  const clearStore = () => {
    localStorage.removeItem(nextURLStoreKey);
  };

  const goToNextURL = goToNextUrlLocation.bind(null, history, clearStore, nextURL);

  return { set: setNextURL, nextURL, goToNextURL, clear: clearStore };
}

export function useSetNextURL() {
  const setNextURL = useSetAtom(nextURLStore);

  return setIfValid;
  function setIfValid(nextURL: unknown) {
    if (isString(nextURL)) setNextURL({ nextURL });
  }
}

function goToNextUrlLocation(
  history: ReturnType<typeof useHistory>,
  clearStore: () => void,
  nextURL: string | undefined,
  fallback?: string,
  clear: boolean = true
) {
  const destinationUrl = getDestinationUrl(nextURL, fallback);
  
  if (!isString(destinationUrl)) {
    return;
  }

  const isRelativeUrl = destinationUrl.includes("://") === false;
  if (isRelativeUrl) {
    history.push(destinationUrl);
    
    if (clear) {
      clearStore();
    }
    return;
  }

  window.location.href = destinationUrl;
}

function getDestinationUrl(nextURL: string | undefined, fallback?: string): string | void {
  let destinationUrl = nextURL;
  if (!isString(destinationUrl)) {
    destinationUrl = fallback;
  }

  if (!isString(destinationUrl)) {
    return console.warn("next url is not valid: ", destinationUrl);
  }

  return destinationUrl;
}
