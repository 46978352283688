export enum ProductCmsType {
  bonusAndEconomicContributions = "bonusAndEconomicContributions",
  deductionsAndFiscalBonus = "deductionsAndFiscalBonus",
  fiscalDeclarationAnd730 = "fiscalDeclarationAnd730",
  certificatesAndSupportServices = "certificatesAndSupportServices",
  financialServices = "financialServices",
  other = "other",
}

export const bonusTypes = [ProductCmsType.bonusAndEconomicContributions, ProductCmsType.deductionsAndFiscalBonus];

export const serviceTypes = [
  ProductCmsType.fiscalDeclarationAnd730,
  ProductCmsType.certificatesAndSupportServices,
  ProductCmsType.financialServices,
  ProductCmsType.other,
];
