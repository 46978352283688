import { Expose } from "class-transformer";

export interface ISessionDTO {
  id: string;

  isActive: boolean;
}

export class SessionDTO implements ISessionDTO {
  @Expose()
  public id!: string;

  @Expose()
  public isActive!: boolean;
}
