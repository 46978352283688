import { useEffect, useMemo, useState } from "react";
import { useChatbotName } from "../../components/Chatbot/hooks/use-chatbot-name/use-chatbot-name";
import { ClientConstantsService } from "../../services/client-constants/client-constants.service";
import { ReplaceSinglePlaceholderProps } from "../../services/client-constants/client-constants.service.interface";
import { useFormValues } from "../useFormValues";
import { CLIENT_CONSTANTS_PLACEHOLDERS } from "./use-client-constants.enum";

export function useClientConstants() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { Nome } = useFormValues();
  const chatbotName = useChatbotName();

  const clientConstantsServiceSingleton = useMemo(function createSingleton() {
    return ClientConstantsService.instance;
  }, []);

  const replacements: ReplaceSinglePlaceholderProps[] = useMemo(
    function memoizedReplacements() {
      return [
        {
          stringToReplace: CLIENT_CONSTANTS_PLACEHOLDERS.userFirstName,
          replaceWith: Nome ?? "",
        },
        {
          stringToReplace: CLIENT_CONSTANTS_PLACEHOLDERS.assistantName,
          replaceWith: chatbotName,
        },
      ];
    },
    [Nome, chatbotName]
  );

  useEffect(function initializeClientConstantsService() {
    async function initService() {
      await ClientConstantsService.instance.initialize();
      setIsLoading(false);
    }

    initService();
  }, []);

  useEffect(
    function updateReplacementStringsInService() {
      clientConstantsServiceSingleton.placeholdersReplacements = replacements;
    },
    [clientConstantsServiceSingleton, replacements]
  );

  function getValueOrDefault(key: string, defaultValue: string): string {
    return clientConstantsServiceSingleton.getClientConstantValueOrDefault({
      key,
      default: defaultValue,
    });
  }

  return {
    isLoading,
    getValueOrDefault,
  };
}
