import axios from "axios";
import { getBonusxApiConfigV2 } from "../../../common/bonusxApiConfig";
import { IApiClientService } from "../../../services/ApiClient/ApiClientService.interface";
import AxiosApiClientInstance from "../../../services/AxiosApiClientInstance/AxiosApiClientInstance";
import { Mapper } from "../../../utils/mapper";
import { ProductDto } from "../dtos/product.dto";
import { IProductDto } from "../dtos/product.dto.interface";
import { ProductsAvailabilitiesDto } from "../dtos/products-availabilities.dto";
import { IProductsRemoteDatasource } from "./products-remote.datasource.interface";

export class ProductsRemoteDataSource implements IProductsRemoteDatasource {
  private readonly _GET_ALL_URL = "/products/new";
  private readonly _CLIENT_OPTIONS = getBonusxApiConfigV2();

  constructor(private readonly _client: IApiClientService = new AxiosApiClientInstance(axios)) {}

  async getAllProducts(): Promise<ProductsAvailabilitiesDto> {
    const { data } = await this._client.get<IProductDto[]>(this._GET_ALL_URL, this._CLIENT_OPTIONS);
    const availableProducts: IProductDto[] = [];
    const notAvailableProducts: IProductDto[] = [];

    for (const product of data) {
      const productDto = Mapper.mapData(ProductDto, product);
      if (productDto.available) {
        availableProducts.push(productDto);
      } else {
        notAvailableProducts.push(productDto);
      }
    }

    const splittedProductsByAvailabilities = new ProductsAvailabilitiesDto({
      available: availableProducts,
      notAvailable: notAvailableProducts,
    });
    return splittedProductsByAvailabilities;
  }
}
