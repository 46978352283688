import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
  GetClientConstantValueOrDefaultProps,
  IClientConstantsService,
  ReplacePlaceholdersProps,
  ReplaceSinglePlaceholderProps,
} from "./client-constants.service.interface";
import { ClientConstantsRepository } from "./repositories/client-constants.repository";
import { IClientConstantsRepository } from "./repositories/client-constants.repository.interface";

export class ClientConstantsService implements IClientConstantsService {
  private static _singletonInstance: ClientConstantsService;
  private _isInitialized = false;
  private _placeholdersReplacements: ReplaceSinglePlaceholderProps[] = [];

  constructor(
    private readonly _clientConstantsRepository: IClientConstantsRepository = new ClientConstantsRepository()
  ) {}

  public static get instance(): ClientConstantsService {
    if (!ClientConstantsService._singletonInstance) {
      ClientConstantsService._singletonInstance = new ClientConstantsService();
    }
    return ClientConstantsService._singletonInstance;
  }

  async initialize() {
    if (this._isInitialized) {
      return;
    }

    try {
      await this._clientConstantsRepository.fetchAllClientConstantsFromRemote();
      this._isInitialized = true;
    } catch (error) {
      this._isInitialized = false;
      return;
    }
  }

  set placeholdersReplacements(replacements: ReplaceSinglePlaceholderProps[]) {
    this._placeholdersReplacements = replacements;
  }

  getClientConstantValueOrDefault({
    key: constantKey,
    default: defaultValue,
  }: GetClientConstantValueOrDefaultProps): string {
    const result = this._clientConstantsRepository.getValueFromCache(constantKey);
    let currentValue;

    if (isUndefined(result) || isUndefined(result?.constantValue)) {
      currentValue = defaultValue;
    } else {
      currentValue = result.constantValue;
    }

    return this.replacePlaceholders({ originalString: currentValue, replacements: this._placeholdersReplacements });
  }

  replacePlaceholders({ originalString, replacements }: Readonly<ReplacePlaceholdersProps>): string {
    if (!replacements || isEmpty(replacements)) {
      return originalString;
    }

    let copiedValue = originalString.concat("");
    for (const replacement of replacements) {
      copiedValue = this._replaceSinglePlaceholder(copiedValue, {
        stringToReplace: replacement.stringToReplace,
        replaceWith: replacement.replaceWith,
      });
    }
    return copiedValue;
  }

  private _replaceSinglePlaceholder(
    originalString: Readonly<string>,
    { stringToReplace, replaceWith }: Readonly<ReplaceSinglePlaceholderProps>
  ): string {
    let updatedString = originalString;
    const replaceRegExp = new RegExp(stringToReplace, "g");
    updatedString = updatedString?.replace(replaceRegExp, replaceWith);
    return updatedString;
  }
}
