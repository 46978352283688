import isUndefined from "lodash/isUndefined";
import { Mapper } from "../../../utils/mapper";
import { ProductsCacheDatasource } from "../datasources/products-cache.datasource";
import { IProductsCacheDatasource } from "../datasources/products-cache.datasource.interface";
import { ProductsRemoteDataSource } from "../datasources/products-remote.datasource";
import { IProductsRemoteDatasource } from "../datasources/products-remote.datasource.interface";
import { IProductDto } from "../dtos/product.dto.interface";
import { ProductsAvailabilitiesDto } from "../dtos/products-availabilities.dto";
import { IProductsAvailabilitiesDto } from "../dtos/products-availabilities.dto.interface";
import { Product } from "../entities/product.entity";
import { ProductsAvailabilities } from "../entities/products-availabilities.entity";
import { IProductsRepository } from "./products.repository.interface";

export class ProductsRepository implements IProductsRepository {
  constructor(
    private readonly _productsRemoteDataSource: IProductsRemoteDatasource = new ProductsRemoteDataSource(),
    private readonly _productsCachedDatasource: IProductsCacheDatasource = new ProductsCacheDatasource()
  ) {}

  resetAllProductsCache(): void {
    this._productsCachedDatasource.resetCache();
  }

  async getAllProducts(): Promise<ProductsAvailabilities> {
    const availabilitiesProductsDto = await this._getAllProductsFromDatasource();

    const productAvailableCollection = Mapper.mapArrayData(Product, availabilitiesProductsDto.available);
    const productNotAvailableCollection = Mapper.mapArrayData(Product, availabilitiesProductsDto.notAvailable);

    const productsAvailabilities = new ProductsAvailabilities(
      productAvailableCollection,
      productNotAvailableCollection
    );
    return productsAvailabilities;
  }

  private async _getAllProductsFromDatasource(): Promise<IProductsAvailabilitiesDto> {
    const cachedAvailabilitiesProductsDto = this._productsCachedDatasource.getAllProducts();
    if (!isUndefined(cachedAvailabilitiesProductsDto)) {
      return cachedAvailabilitiesProductsDto;
    }

    const remoteAvailabilitiesProductsDto = await this._getAllRemoteProductsAndSyncCache();
    return remoteAvailabilitiesProductsDto;
  }

  private async _getAllRemoteProductsAndSyncCache() {
    const remoteAvailabilitiesProductsDto = await this._productsRemoteDataSource.getAllProducts();
    this._productsCachedDatasource.setAllProducts(remoteAvailabilitiesProductsDto);
    return remoteAvailabilitiesProductsDto;
  }

  async getProductsByUniqueIdentifiers(uniqueIdentifiers: string[]): Promise<ProductsAvailabilities> {
    const availabilitiesProductsDto = await this._getProductsByUniqueIdentifiersFromDatasource(uniqueIdentifiers);

    const productAvailableCollection = Mapper.mapArrayData(Product, availabilitiesProductsDto.available);
    const productNotAvailableCollection = Mapper.mapArrayData(Product, availabilitiesProductsDto.notAvailable);

    const productsAvailabilities = new ProductsAvailabilities(
      productAvailableCollection,
      productNotAvailableCollection
    );
    return productsAvailabilities;
  }

  private async _getProductsByUniqueIdentifiersFromDatasource(
    uniqueIdentifiers: string[]
  ): Promise<IProductsAvailabilitiesDto> {
    const cachedAvailabilitiesProductsDto =
      this._productsCachedDatasource.getProductsByUniqueIdentifiers(uniqueIdentifiers);
    if (!isUndefined(cachedAvailabilitiesProductsDto)) {
      return cachedAvailabilitiesProductsDto;
    }

    const remoteAvailabilitiesProductsDto = await this._getAllRemoteProductsAndSyncCache();

    const filterByUniqueIdentifiers = this._filterByUniqueIdentifiers.bind(null, uniqueIdentifiers);
    const availableProductsDto = remoteAvailabilitiesProductsDto.available.filter(filterByUniqueIdentifiers);
    const notAvailableProductsDto = remoteAvailabilitiesProductsDto.notAvailable.filter(filterByUniqueIdentifiers);
    const productsCollection: IProductsAvailabilitiesDto = {
      available: availableProductsDto,
      notAvailable: notAvailableProductsDto,
    };
    const filteredRemoteAvailabilitiesProductsDto = new ProductsAvailabilitiesDto(productsCollection);
    return filteredRemoteAvailabilitiesProductsDto;
  }

  private _filterByUniqueIdentifiers(this: null, uniqueIdentifiers: string[], product: IProductDto): boolean {
    return uniqueIdentifiers.includes(product.uniqueIdentifier);
  }
}
