import { AxiosError } from "axios";
import CodiceFiscale from "codice-fiscale-js";
//@ts-ignore
import IBAN from "iban";
import flatten from "lodash/flatten";
import moment from "moment";
import { stringify } from "qs";
import { ROOT_STORE_STORAGE_INDEX } from "../common/env";
import { checkForStep, getValuesFromName, switchForStep } from "../functions/formutil";
import { IForm, IStepForm } from "../interfaces/IForm";
export * as session from "./session";

export function calculatePercentageForm(form: IForm, activeStepName?: string, formValues?: any, exact?: boolean) {
  const forms = flatten(form?.sections?.map((s) => s.forms));
  // const stepsWithNextStep = forms;
  const stepNameCollection = new Array(forms.length);

  const stepsMap = new Map<string, { form: IStepForm; index: number }>(
    forms.map(function toStepNameAndForm(form, index) {
      stepNameCollection[index] = form.stepname;
      return [form.stepname, { form, index }];
    })
  );

  function getStepIndexByStepName(nextStep: string) {
    const defaultStepIndex = -1;
    return stepsMap.get(nextStep)?.index ?? defaultStepIndex;
  }

  let currentStepIndex = -1;
  if (activeStepName) {
    currentStepIndex = getStepIndexByStepName(activeStepName) ?? currentStepIndex;
  }

  if (!formValues && currentStepIndex === -1) {
    return 0;
  }

  const minAcceptedSteps = 1;
  const totalSteps = stepsMap.size || minAcceptedSteps;
  let calcPercentage = (currentStepIndex / totalSteps) * 100;
  calcPercentage = Math.max(calcPercentage, 0);

  if (!formValues) {
    return ~~(calcPercentage + 0.5);
  }

  let activeStepIndex = -1;
  let nextStep = stepNameCollection[0];
  let prevNextNextStep = "";
  let isValid = true;

  while (isValid && !["end", "finish"].includes(nextStep) && prevNextNextStep != nextStep) {
    activeStepIndex = nextStep ? getStepIndexByStepName(nextStep) : activeStepIndex;
    const { currentStepFieldName, currentField } = extractStepFieldName(stepNameCollection, activeStepIndex, stepsMap);

    isValid = !currentStepFieldName || (currentStepFieldName && !validate(currentField, formValues));

    if (isValid) {
      prevNextNextStep = nextStep;
      nextStep = calculateNextStep(activeStepIndex, formValues?.[currentStepFieldName], {
        forms,
        formValues,
      });
    }
  }

  calcPercentage = (activeStepIndex / totalSteps) * 100;
  calcPercentage = Math.max(calcPercentage, 0);

  const { currentStepFieldName, currentField } = extractStepFieldName(stepNameCollection, activeStepIndex, stepsMap);

  isValid = !currentStepFieldName || (currentStepFieldName && !validate(currentField, formValues));

  let calculatedNextStep: string = "";
  calculatedNextStep = calculateNextStep(activeStepIndex, formValues?.[currentStepFieldName], {
    forms,
    formValues,
  });

  //console.log("CALCULATE FORM", form?.name, activeStepIndex, calculatedNextStep, calcPercentage);

  if ((calculatedNextStep === "finish" || calculatedNextStep === "end") && isValid) {
    if (calculatedNextStep === "finish" && !exact) return 999; //! debug purpuse

    return 100;
  }

  return ~~(calcPercentage + 0.5);
}

export const parseDefaultValue = (defaultValue: string, formValues: any[]) => {
  return defaultValue;
};

function extractStepFieldName(
  stepNameCollection: any[],
  activeStepIndex: number,
  stepsMap: Map<string, { form: IStepForm; index: number }>
) {
  const stepName = stepNameCollection[activeStepIndex];
  const currentField = stepsMap.get(stepName)?.form.fields?.[0];
  const currentStepFieldName = currentField?.name;
  return { currentStepFieldName, currentField };
}

export function getCurrentStateFromLocalStorage(initialState?: any) {
  const locationToCheck = ROOT_STORE_STORAGE_INDEX;

  const persistedValue = localStorage.getItem(locationToCheck);

  if (!persistedValue) return initialState;

  const parsedState = JSON.parse(persistedValue);

  return {
    ...parsedState,
    forms: initialState?.forms || parsedState?.forms,
    header: initialState?.header,
  };
}

export const apiErrorParser = (axiosError: AxiosError) => {
  const resp = axiosError.response?.data;
  let error = "genericError";

  if (resp?.errors) {
    error = resp?.errors?.[0]?.param || "genericError";
  } else {
    error = resp?.message || "genericError";
  }

  return error;
};

export function getFullName(person?: { name?: string; surname?: string }) {
  return (person?.name || "") + " " + (person?.surname || "");
}

// export const apiErrorParser =  async <T extends Object> (api: Promise<AxiosResponse<T>>) => {
//   return new Promise((resolve) => {
//    api
//       .then((res) => {
//         if (res.status >= 300 || res.status < 200) {
//           res.json().then((bodyErrors) => {
//             if (bodyErrors?.errors) {
//               resolve({ error: bodyErrors?.errors?.[0]?.param || "genericError" });
//             } else {
//               resolve({ error: bodyErrors?.message || "genericError" });
//             }
//           });
//         } else {
//           resolve(res.json());
//         }
//       })
//       .catch(console.log);
//   });
// };

export function backendMediaUrlParser(
  fileUrl?: string,
  options?: { download?: boolean; filename?: string; width?: number; height?: number }
) {
  if (!fileUrl) return undefined;
  if (fileUrl.includes("blob:")) return fileUrl;

  const { download, filename, width, height } = options || {};

  let url = process.env.REACT_APP_MAIN_URL + "/sapi/media/" + fileUrl;

  if (width || height) {
    url = `${url}?${width ? "w=" + width : ""}${height ? (width ? "&" : "") + ("h=" + height) : ""}`;
  }

  if (download || filename) {
    let tmpParams = stringify({ download, filename });
    url += `?${tmpParams}`;
  }

  return url;
}

export const DocumentCategory = [
  { id: 0, value: "Personali" },
  { id: 1, value: "Redditi e lavoro" },
  { id: 2, value: "Proprietà e affitti" },
  { id: 3, value: "Conti e titoli " },
  { id: 4, value: "Veicoli" },
  { id: 5, value: "Certificati e ricevute" },
  { id: 6, value: "ISEE" },
  { id: 8, value: "Pratica" },
  { id: 9, value: "Spese sostenute" },
  { id: 10, value: "Previdenza" },
  { id: 11, value: "Fisco" },
  { id: 7, value: "Altro" },
];

export const ServiceCategory = [
  { id: 0, value: "Famiglia" },
  { id: 1, value: "Giovani" },
  { id: 2, value: "Anziani" },
  { id: 3, value: "Studenti " },
  { id: 4, value: "Casa" },
  { id: 5, value: "Veicoli e trasporti" },
  { id: 6, value: "Imprese" },
  { id: 7, value: "Lavoratori e disoccupati" },
  { id: 8, value: "Pensionati" },
  { id: 9, value: "Salute" },
  { id: 10, value: "Tempo libero" },
  { id: 11, value: "Servizi Accessori" },
];

export const ToSignDocumentCategory = [
  { id: 0, value: "Mandato" },
  { id: 1, value: "Trattamento Dati" },
  { id: 2, value: "Dichiarazione sostitutiva unica (DSU)" },
  { id: 4, value: "Modello dimissioni volontarie" },
  { id: 5, value: "Autodichiarazione di residenza" },
  { id: 6, value: "Autodichiarazione del reddito" },
  { id: 7, value: "ISEE Difforme" },
  { id: 3, value: "Altro" },
];

export const SelectMainActivityOptions = [
  { id: 0, value: "Seleziona una attività" },
  { id: 1, value: "Lavoro dipendente a tempo indeterminato" },
  { id: 2, value: "Lavoro dipendente a tempo determinato o con contratto di apprendistato" },
  { id: 3, value: "Lavoro con contratto di somministrazione (“interinale”)" },
  {
    id: 4,
    value:
      "Lavoro o disoccupazione con sostegno al reddito (cassa integrazione, NASpI, lavori socialmente utili, ecc.) ",
  },
  { id: 5, value: "Lavoro parasubordinato (coll. a progetto o Co.Co.Co)" },
  { id: 6, value: "Lavoro accessorio (con voucher, occasionale, tirocinio/stage, ecc.)" },
  { id: 7, value: "Lavoro autonomo" },
  { id: 8, value: "Senza occupazione" },
  { id: 9, value: "Pensione" },
  { id: 10, value: "Cura della casa (casalinga/o)" },
  { id: 11, value: "Studio (studente universitario, liceale, ecc.)" },
  { id: 12, value: "Altro" },
];

export const SelectParentOptions = [
  { id: 1, value: "Coniuge" },
  { id: 2, value: "Unito civilmente" },
  { id: 3, value: "Figlio/a" },
  { id: 4, value: "Minore in affido preadottivo" },
  { id: 5, value: "Genitore" },
  { id: 6, value: "Sorella/Fratello" },
  { id: 7, value: "Nonno/a" },
  { id: 8, value: "Nipote" },
  { id: 9, value: "Zio/a" },
  { id: 10, value: "Cugino/a" },
  { id: 11, value: "Altro (convivente di fatto, ecc.)" },
];

export const necessaryDataPurchaseOptions = [
  { id: "personal_data", value: "Dati personali" },
  { id: "family_questionnaire", value: "Nucleo familiare" },
  { id: "family_data", value: "Dati dei familiari" },
  // { id: "citizenship_questionnaire", value: "Dati personali" },
  { id: "income_questionnaire", value: "Redditi" },
  { id: "full_questionnaire", value: "Patrimonio" },
  // { id: "heritage_questionnaire", value: "Patrimonio" },
];

/**
 * Trims the string to {max} value and adds to the end "..."
 *
 * @param {string} value
 * @param {number} max
 */
export function trimStringToMax(value: string | undefined, max: number) {
  if (!value) return "";
  if (value.length < max) {
    return value;
  }

  return value.substr(0, max) + "...";
}

const validate = (field: any, v: any) => {
  let value = v?.[field?.name];
  if (field?.type === "yesno") {
    if (field?.optional) return false;
    return value === undefined;
  }

  switch (field?.validation) {
    case "multiple_has":
      const parsedValue = ((typeof value === "string" ? JSON.parse(value) : value) || []).filter((x: any) => x);

      const hasError = parsedValue?.some((x: any) => {
        const keys = Object.keys(x);
        return keys.some((key) => !x[key]);
      });
      if (hasError || parsedValue?.length === 0) return true;

      break;
    case "tax_code":
      if (!CodiceFiscale.check(value)) return true;
      break;
    case "iban":
      if (!IBAN.isValid(value)) return true;
      break;
    case "has":
      if (field.type === "multipleselection") {
        if (
          ((value || []).length <= 0 || ((value || []).length === 1 && (value || []).includes(""))) &&
          !field.optional
        )
          return true;
      }
      if ((!value || (value && value.length < 1)) && !field.optional) return true;
      break;
    case "eval":
      const moment = require("moment");
      moment.locale("it");

      //  if the field is optional but the user type a value, then does the check
      //  if the field is optional but the user doesn't type anything, doesn't the check
      //  value.length > 0 return false and doesn't check the value

      try {
        //! here was isNaN
        if (field.type === "number") {
          if (!eval(field.expression) && (!field.optional || (value?.toString().length > 0 && !isNaN(value)))) {
            return true;
          }
        } else if (!eval(field.expression) && (!field.optional || value?.toString().length > 0)) {
          return true;
        }
      } catch (err) {
        return true;
      }
      break;
    case "number":
      if (!value || (value && isNaN(value))) return true;
      break;
    case "selected":
      if (!value || (value && value.length < 1)) return true;
      break;

    default:
      break;
  }

  return false;
};

export const calculateNextStep = (activeStep: number, response: boolean, props: { forms: any[]; formValues: any }) => {
  const nextStepData: string | string[] = props?.forms?.[activeStep]?.nextstep;
  const actionString = typeof nextStepData === "string" ? nextStepData : (nextStepData || []).join("");

  const actionCollectin = actionString?.split(":");

  if (actionCollectin.length > 1) {
    const actionName = actionCollectin[0] as "check" | "select" | "eval" | "switch";

    if (actionName === "check") {
      return checkForStep(
        actionCollectin[1],
        props.formValues[actionCollectin[2]],
        actionCollectin[3],
        actionCollectin[4],
        actionCollectin[5]
      );
    }

    if (actionName === "select") {
      const fieldName = actionCollectin[1];
      const value = getValuesFromName(props.formValues, fieldName);
      const stepIndex = 1 + parseInt(value);
      const next = actionCollectin[stepIndex];
      return next;
    }

    if (actionName === "eval") {
      const v = props.formValues;

      const evalString = actionCollectin[1];
      const evaluation = eval(evalString);
      if (evaluation) {
        return actionCollectin[2];
      }
      return actionCollectin[3];
    }

    if (actionName === "switch") {
      const v = props.formValues;

      actionCollectin.shift();
      return switchForStep(v, actionCollectin.join(":"), response);
    }

    if (response) {
      const next = actionName;
      return next;
    } else {
      const next = actionCollectin[1];
      return next;
    }
  }
  const next = actionString;

  return next;
};

export const elaborateFamilyOptions = (familyData: any[]) => {
  return (familyData || [])?.map((x) => ({ [x?.id || ""]: getFullName(x) }));
};

export function rawToArray(value?: any | any[]) {
  if (!value) return [];
  if (typeof value !== "object") return [value];
  else return value;
}

export function getBirthDateFromTaxCode(taxCode: string) {
  if (!taxCode) return undefined;
  if (!CodiceFiscale.check(taxCode)) return undefined;

  const computed = CodiceFiscale.computeInverse(taxCode);
  const momentComputed = moment()
    .year(computed.year)
    .month(computed.month - 1)
    .date(computed.day);

  return momentComputed;
}

export function findLastFieldToBeCompleted(forms: IForm[], formValues: any) {
  let lastFieldToBeCompleted = "";

  if (forms.length > 0) {
    const lastForm: any = forms[forms.length - 1];
    const innerForms = lastForm.sections[0].forms;

    let lastInnerForm = innerForms[innerForms.length - 1];

    lastFieldToBeCompleted = lastInnerForm?.fields?.[0].name;
    if (formValues[lastFieldToBeCompleted] === undefined) {
      let lastInnerForm = innerForms?.[innerForms.length - 2];

      lastFieldToBeCompleted = lastInnerForm?.fields[0]?.name;

      if (formValues[lastFieldToBeCompleted] === undefined) {
        let lastInnerForm = innerForms?.[innerForms.length - 3];

        lastFieldToBeCompleted = lastInnerForm?.fields[0]?.name;
      }
    }
  }

  return lastFieldToBeCompleted;
}

export function replaceMemberField(s: string, member: any) {
  s = s.replace(/%(.*?)%/gi, function (a, b) {
    const v = member[b];
    return v;
  });

  return s;
}
