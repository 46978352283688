import { PropsWithChildren, useEffect, useMemo, useReducer } from "react";
import { useIsLogged } from "../../../hooks/useIsLogged";
import { ChatbotContext } from "../chatbot.context";
import { chatbotInitialState, chatbotReducer } from "../lib/reducer/chatbot.reducer";
import { AI_CHATBOT_ACTIONS } from "../lib/reducer/chatbot.reducer.enum";
import { ChatbotService } from "../services/chatbot.service";
import { resetChatbot } from "./chatbot-provider-action";

const chatbotService = new ChatbotService();

export function ChatbotProvider({ children }: Readonly<PropsWithChildren<{}>>): JSX.Element {
  const [state, dispatch] = useReducer(chatbotReducer, chatbotInitialState);
  const isLogged = useIsLogged();

  const value = useMemo(
    function memoizeChatbotProviderValue() {
      return { state, dispatch };
    },
    [state, dispatch]
  );

  useEffect(
    function initializeChatbotMessages() {
      if (!isLogged) {
        const resetCommand = resetChatbot();
        return dispatch(resetCommand);
      }

      async function populateChatbotMessagesWithLatestSession() {
        const oldMessages = await chatbotService.getActiveSessionMessages();

        if (oldMessages && oldMessages.length > 0) {
          dispatch({
            type: AI_CHATBOT_ACTIONS.POPULATE_CHAT_MESSAGES,
            messagesArrayPayloadValue: oldMessages,
          });
        }
      }
      populateChatbotMessagesWithLatestSession();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLogged]
  );

  return <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>;
}
