import moment from "moment";
const year = moment().year();

const patrimonio = {
  name: "patrimonio",
  title: "Patrimonio",
  iconUrl: "/images/money-pig.svg",
  sections: [
    {
      name: "Patrimonio",
      forms: [
        {
          stepname: "10",
          subtitle: [
            `%Nome%, parliamo di patrimonio: le prossime domande riguarderanno tutti i patrimoni posseduti da te e dal tuo nucleo familiare nel [year-2].`,
          ],
          guided: [
            "Se la tua situazione patrimoniale è peggiorata dopo il [year-2], dal 1° aprile [year] puoi comunicarla con l'ISEE Corrente che fa riferimento al patrimonio posseduto al 31 dicembre [year-1].",
          ],
          nextstep: "10a_2",
          fields: [],
        },
        {
          stepname: "10a_2",
          subtitle: ["Partiamo dalla casa di abitazione: in quale Comune abita il tuo nucleo?"],
          guided: [
            "La casa di abitazione è quella in cui, di solito, risiedono tutti i componenti del nucleo familiare. Nel caso in cui i componenti non risiedano attualmente nella stessa abitazione, puoi indicare una delle abitazioni in cui risiede un membro della famiglia.",
            "Ah, la casa di abitazione deve essere all'interno del territorio italiano.",
          ],
          nextstep: "10a",
          fields: [
            {
              name: "Posizione",
              placeholder: "Digita il comune",
              type: "comunechoice",
              validation: "has",
              errorText: "Digita un comune e selezionalo dalla lista",
              optional: false,
            },
          ],
        },
        {
          stepname: "10a",
          subtitle: ["La casa di abitazione è:"],
          guided: [
            `Se il nucleo abita in una casa popolare metti "In affitto", se abita in una residenza universitaria concessa senza pagamento di affitto metti "Altro", se invece ha un "diritto reale di godimento" (uso, abitazione, servitù, superficie ed enfiteusi) metti "Di proprietà o in usufrutto". In quest'ultima categoria non rientrano la nuda proprietà e il comodato d'uso`,
          ],
          nextstep: "select:tipologiacasa:10b2:10b:10b2",
          fields: [
            {
              name: "tipologiacasa",
              placeholder: "Tipologia",
              type: "selection",
              options: [
                {
                  "": "Seleziona un valore",
                },
                {
                  "1": "Di proprietà o in usufrutto",
                },
                {
                  "2": "In affitto (con contratto registrato)",
                },
                {
                  "3": "Altro (es. comodato gratuito)",
                },
              ],
              validation: "selected",
              errorText: "Seleziona uno dei valori",
              optional: false,
            },
          ],
        },
        {
          stepname: "10b",
          subtitle: "Quant'è il canone di affitto annuo?",
          guided: [
            "Per il canone di affitto si fa riferimento all'anno in corso, cioè al [year].",
            "In caso di contratto di locazione (affitto) cointestato a membri di diverse famiglie, indica solo la quota relativa al tuo nucleo familiare.",
          ],
          nextstep: "10b2",
          fields: [
            {
              name: `Canoneaffittoannuo${year}`,
              dependencies: ["tipologiacasa"],
              placeholder: "Canone affitto annuo",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['Canoneaffittoannuo${year}']) && v['Canoneaffittoannuo${year}'] >= 1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10b2",
          subtitle: ["Al 31 dicembre [year-2] la casa di abitazione era:"],
          guided: [
            `Se il nucleo abitava in una casa popolare metti "In affitto", se abitava in una residenza universitaria concessa senza pagamento di affitto metti "Altro", se invece aveva un "diritto reale di godimento" (uso, abitazione, servitù, superficie ed enfiteusi) metti "Di proprietà o in usufrutto". In quest'ultima categoria non rientrano la nuda proprietà e il comodato d'uso.`,
          ],
          nextstep: `select:tipologiacasa${year - 2}:10c:10e:10e`,
          fields: [
            {
              name: `tipologiacasa${year - 2}`,
              placeholder: "Tipologia",
              type: "selection",
              options: [
                {
                  "": "Seleziona un valore",
                },
                {
                  "1": "Di proprietà o in usufrutto",
                },
                {
                  "2": "In affitto (con contratto registrato)",
                },
                {
                  "3": "Altro (es. comodato gratuito)",
                },
              ],
              validation: "selected",
              errorText: "Seleziona uno dei valori",
              optional: false,
            },
          ],
        },
        {
          stepname: "10c",
          subtitle:
            "Questa è più difficile: qual è stata la rendita catastale dell'abitazione, inclusi cantina e garage, al 31 dicembre [year-2]?",
          guided: [
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: "10d",
          fields: [
            {
              name: `Renditaabitazione${year}`,
              dependencies: [`tipologiacasa${year - 2}`],
              placeholder: "Rendita catastale",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['Renditaabitazione${year}']) && v['Renditaabitazione${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10d",
          subtitle: ["La casa di abitazione è stata acquistata con un mutuo?"],
          yesno: true,
          nextstep: "10d_2:10e",
          fields: [
            {
              name: "Mutuocasa",
              dependencies: [`tipologiacasa${year - 2}`],
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10d_2",
          subtitle: "Calcolatrice alla mano: quanto rimaneva del mutuo al 31 dicembre [year-2]?",
          guided: ['Se non rimaneva niente al 31 dicembre [year-2], metti "0".'],
          nextstep: "10e",
          fields: [
            {
              dependencies: [`tipologiacasa${year - 2}`, "Mutuocasa"],
              name: `Mutuocasaresiduo${year}`,
              placeholder: "Mutuo residuo",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['Mutuocasaresiduo${year}']) && value.length > 0 && v['Mutuocasaresiduo${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              optional: true,
            },
          ],
        },
        {
          stepname: "10e",
          subtitle: [
            "%Nome%, esclusa la casa di abitazione, uno o più componenti del tuo nucleo possedeva immobili (fabbricati o terreni) al 31 dicembre [year-2]?",
          ],
          guided: [
            "Seleziona SI sia in caso di proprietà che in caso di diritto reale di godimento, cioè usufrutto, uso, abitazione, servitù, superficie ed enfiteusi. Non rientrano in questa categoria la locazione (affitto), la nuda proprietà e il comodato d'uso.",
          ],
          yesno: true,
          nextstep: "10f:10p",
          fields: [
            {
              name: "Immobiliterreni",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10f",
          subtitle: "Seleziona ogni tipologia di immobile posseduta al 31 dicembre [year-2]:",
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliA2_C2_C6_C7 === true", "step":"10g"},',
            '{"eval":"v.ImmobiliA_10_D5 === true", "step":"10h"},',
            '{"eval":"v.ImmobiliC1 === true", "step":"10i"},',
            '{"eval":"v.ImmobiliD === true", "step":"10j"},',
            '{"eval":"v.TerreniAgricoli === true", "step":"10k"},',
            '{"eval":"v.AreeEdificabili === true", "step":"10l"},',
            '{"eval":"v.ImmobiliEsteri === true", "step":"10l2"},',
            '{"eval":true, "step":"10p"}',
            "]",
          ],
          fields: [
            {
              dependencies: ["Immobiliterreni"],
              name: "ImmobiliA2_C2_C6_C7",
              title:
                "Immobili ad uso abitativo diversi dalla casa di abitazione e relative pertinenze (gruppo catastale A, tranne A-10, e categorie catastali C-2, C-6 e C-7)",
              type: "checkbox",
            },
            {
              dependencies: ["Immobiliterreni"],
              name: "ImmobiliA_10_D5",
              title:
                "Uffici, studi privati o istituti di credito, cambio e assicurazione (categorie catastali A-10 e D-5)",
              type: "checkbox",
            },
            {
              dependencies: ["Immobiliterreni"],
              name: "ImmobiliC1",
              title: "Negozi o botteghe (categoria catastale C-1)",
              type: "checkbox",
            },
            {
              dependencies: ["Immobiliterreni"],
              name: "ImmobiliD",
              title:
                "Alberghi, opifici, teatri, cinema, case di cura, ospedali e fabbricati per esercizi sportivi o per le speciali esigenze di un'attività industriale, commerciale o agricola (gruppo catastale D, tranne D-5)",
              type: "checkbox",
            },
            {
              dependencies: ["Immobiliterreni"],
              name: "TerreniAgricoli",
              title: "Terreni agricoli o boschi",
              type: "checkbox",
            },
            {
              dependencies: ["Immobiliterreni"],
              name: "AreeEdificabili",
              title: "Aree edificabili",
              type: "checkbox",
            },
            {
              dependencies: ["Immobiliterreni"],
              name: "ImmobiliEsteri",
              title: "Immobili all'estero",
              type: "checkbox",
            },
          ],
        },
        {
          stepname: "10g",
          subtitle:
            "Qual è la rendita catastale totale degli immobili ad uso abitativo diversi dalla casa di abitazione?",
          guided: [
            "Intendiamo il gruppo catastale A, tranne A-10, e le categorie catastali C-2, C-6 e C-7. Si tratta, ad esempio, di seconde case al mare o in montagna. Dovresti sommare le rendite catastali dei vari immobili posseduti dai membri del nucleo familiare",
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliA_10_D5 === true", "step":"10h"},',
            '{"eval":"v.ImmobiliC1 === true", "step":"10i"},',
            '{"eval":"v.ImmobiliD === true", "step":"10j"},',
            '{"eval":"v.TerreniAgricoli === true", "step":"10k"},',
            '{"eval":"v.AreeEdificabili === true", "step":"10l"},',
            '{"eval":"v.ImmobiliEsteri === true", "step":"10l2"},',
            '{"eval":true, "step":"10m_2"}',
            "]",
          ],
          fields: [
            {
              dependencies: ["Immobiliterreni", "ImmobiliA2_C2_C6_C7"],
              name: `RenditaA2_C2_C6_C7${year}`,
              type: "currency",
              placeholder: "Rendita catastale",
              validation: "eval",
              expression: `!isNaN(v['RenditaA2_C2_C6_C7${year}']) && v['RenditaA2_C2_C6_C7${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10h",
          subtitle:
            "Qual è la rendita catastale totale di uffici, studi privati o istituti di credito, cambio e assicurazione?",
          guided: [
            "Intendiamo le categorie catastali A-10 e D-5. Dovresti sommare le rendite catastali dei vari immobili posseduti dai membri del nucleo familiare.",
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliC1 === true", "step":"10i"},',
            '{"eval":"v.ImmobiliD === true", "step":"10j"},',
            '{"eval":"v.TerreniAgricoli === true", "step":"10k"},',
            '{"eval":"v.AreeEdificabili === true", "step":"10l"},',
            '{"eval":"v.ImmobiliEsteri === true", "step":"10l2"},',
            '{"eval":true, "step":"10m_2"}]',
          ],
          fields: [
            {
              dependencies: ["Immobiliterreni", "ImmobiliA_10_D5"],
              name: `RenditaA_10_D5${year}`,
              type: "currency",
              placeholder: "Rendita catastale",
              validation: "eval",
              expression: `!isNaN(v['RenditaA_10_D5${year}']) && v['RenditaA_10_D5${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10i",
          subtitle: "Qual è la rendita catastale totale di negozi e botteghe?",
          guided: [
            "Intendiamo la categoria catastale C-1. Dovresti sommare le rendite catastali dei vari immobili posseduti dai membri del nucleo familiare.",
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliD === true", "step":"10j"},',
            '{"eval":"v.TerreniAgricoli === true", "step":"10k"},',
            '{"eval":"v.AreeEdificabili === true", "step":"10l"},',
            '{"eval":"v.ImmobiliEsteri === true", "step":"10l2"},',
            '{"eval":true, "step":"10m_2"}]',
          ],
          fields: [
            {
              dependencies: ["Immobiliterreni", "ImmobiliC1"],
              name: `RenditaC1${year}`,
              type: "currency",
              placeholder: "Rendita catastale",
              validation: "eval",
              expression: `!isNaN(v['RenditaC1${year}']) && v['RenditaC1${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10j",
          subtitle:
            "Qual è la rendita catastale totale di alberghi, opifici, teatri, cinema, case di cura, ospedali e fabbricati per esercizi sportivi o per le speciali esigenze di un’attività industriale, commerciale o agricola?",
          guided: [
            "Intendiamo il gruppo catastale D, tranne D-5. Dovresti sommare le rendite catastali dei vari immobili posseduti dai membri del nucleo familiare.",
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.TerreniAgricoli === true", "step":"10k"},',
            '{"eval":"v.AreeEdificabili === true", "step":"10l"},',
            '{"eval":"v.ImmobiliEsteri === true", "step":"10l2"},',
            '{"eval":true, "step":"10m_2"}]',
          ],
          fields: [
            {
              dependencies: ["Immobiliterreni", "ImmobiliD"],
              name: `RenditaD${year}`,
              type: "currency",
              placeholder: "Rendita catastale",
              validation: "eval",
              expression: `!isNaN(v['RenditaD${year}']) && v['RenditaD${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10k",
          subtitle: "Qual è il reddito dominicale totale dei terreni agricoli o boschivi?",
          guided: [
            "Il reddito dominicale è un valore che puoi trovare all'interno della visura catastale del terreno.",
            "Se uno o più componenti del nucleo possiedono solo una parte del reddito, indicaci il reddito dominicale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.AreeEdificabili === true", "step":"10l"},',
            '{"eval":"v.ImmobiliEsteri === true", "step":"10l2"},',
            '{"eval":true, "step":"10m_2"}]',
          ],
          fields: [
            {
              dependencies: ["Immobiliterreni", "TerreniAgricoli"],
              name: `RenditaTerreniAgricoli${year}`,
              type: "currency",
              placeholder: "Reddito dominicale",
              validation: "eval",
              expression: `!isNaN(v['RenditaTerreniAgricoli${year}']) && v['RenditaTerreniAgricoli${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10l",
          subtitle: "Qual è il valore ai fini IMU delle aree edificabili?",
          guided: [
            "Non lo conosci? Chiedi pure al tuo commercialista e continua più tardi ;) ",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'area edificabile, indica il valore IMU per la parte posseduta (es. valore IMU = 40.000, quota = 50%, indicare 20.000). Vanno sommati i valori IMU delle varie aree edificabili possedute dai membri del nucleo familiare.",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliEsteri === true", "step":"10l2"},',
            '{"eval":true, "step":"10m_2"}]',
          ],
          fields: [
            {
              dependencies: ["Immobiliterreni", "AreeEdificabili"],
              name: `RenditaAreeEdificabili${year}`,
              type: "currency",
              placeholder: "Valore IMU",
              validation: "eval",
              expression: `!isNaN(v['RenditaAreeEdificabili${year}']) && v['RenditaAreeEdificabili${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10l2",
          subtitle: "Qual è il valore ai fini IVIE degli immobili posseduti all'estero?",
          guided: [
            "IVIE: imposta sul valore degli immobili situati all’estero. Il valore ai fini IVIE per i Paesi appartenenti alla Unione Europea, Norvegia e Islanda è quello catastale. In mancanza del valore catastale, si fa riferimento al costo dell'immobile che risulta dall’atto di acquisto o, in assenza, al valore di mercato. Per gli altri Paesi, il valore dell’immobile è costituito dal costo risultante dall'atto di acquisto o, in assenza, dal valore di mercato. Per gli immobili acquisiti per successione o donazione, il valore è quello dichiarato nella dichiarazione di successione o negli altri atti previsti dagli ordinamenti esteri con finalità analoghe.",
            "Puoi trovare questo valore nella dichiarazione dei REDDITI Persone Fisiche [year-1], relativo ai redditi [year-2], nel quadro RW, nel rigo dove è indicato l'immobile, riquadro 8.",
            "",
            "Se possiedi solo una parte dell'immobile, indica il valore IVIE per la parte posseduta (es. valore IVIE = 10.000, quota = 50%, indicare 5.000). Vanno sommati i valori IVIE dei vari immobili all'estero posseduti dai membri del nucleo familiare.",
          ],
          nextstep: "10m_2",
          fields: [
            {
              dependencies: ["Immobiliterreni", "ImmobiliEsteri"],
              name: `RenditaImmobiliEsteri${year}`,
              type: "currency",
              placeholder: "Valore IVIE",
              validation: "eval",
              expression: `!isNaN(v['RenditaImmobiliEsteri${year}']) && v['RenditaImmobiliEsteri${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10m_2",
          subtitle: "Uno o più componenti ha acquistato uno dei fabbricati o dei terreni indicati con un mutuo?",
          nextstep: "10m:10p",
          yesno: true,
          fields: [
            {
              dependencies: ["Immobiliterreni"],
              name: "MutuoAltro",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10m",
          subtitle: "Qual è la quota totale rimanente dei mutui al 31 dicembre [year-2]?",
          guided: [`Se non c'era un mutuo residuo al 31 dicembre [year-2], metti "0".`],
          nextstep: "10p",
          fields: [
            {
              dependencies: ["Immobiliterreni", "MutuoAltro"],
              name: `QuotaMutuoAltro${year}`,
              type: "currency",
              placeholder: "Mutuo residuo",
              validation: "eval",
              expression: `!isNaN(v['QuotaMutuoAltro${year}']) && value.length > 0 && v['QuotaMutuoAltro${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              optional: false,
            },
          ],
        },
        {
          stepname: "10p",
          subtitle:
            "Indica il valore totale del patrimonio mobiliare (ad esempio: carte prepagate, conti in banca, azioni, buoni fruttiferi) del nucleo familiare al 31 dicembre [year-2]:",
          guided: [
            "Si tratta del valore totale di conti corrente, conti deposito, carte prepagate, titoli di stato, azioni, obbligazioni, buoni fruttiferi e altri strumenti finanziari posseduti. In caso di conti o altri rapporti finanziari cointestati con persone esterne al nucleo familiare, indica la quota posseduta dal nucleo.",
            "Per i conti e le carte prepagate, considera il saldo al 31 dicembre [year-2] (ovvero quanto avevi sul conto o la carta a fine anno).",
          ],
          nextstep: "11",
          fields: [
            {
              name: `PatrimonioMobiliare${year}`,
              dependencies: ["Membrinucleo"],
              placeholder: "Valore patrimonio mobiliare",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['PatrimonioMobiliare${year}']) && value.length > 0 && v['PatrimonioMobiliare${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              optional: false,
            },
          ],
        },
        {
          stepname: "11",
          subtitle:
            "Il patrimonio complessivo del nucleo familiare al 31 dicembre [year-1] è di almeno il 20% inferiore rispetto al patrimonio nel [year-2]?",
          guided: [
            "Selezionando SI, a partire dal 1 aprile dell'anno in corso, è possibile ridurre il valore ISEE tenendo conto anche di peggioramenti della situazione patrimoniale, cioè la diminuzione dei risparmi in banca, la perdita di un'abitazione e altre riduzioni del patrimonio riportato nell'Attestazione ISEE ordinaria (che fa riferimento all'anno [year-2]).",
          ],
          yesno: true,
          nextstep: [
            "switch:[",
            '{"eval":"v.tipologiacasa == 1 && response == true", "step":"10cB"},',
            '{"eval":"v.Mutuocasa == true && response == true", "step":"10d_2B"},',
            '{"eval":"response == false", "step":"finish"},',
            '{"eval":true, "step":"10eB"}]',
          ],
          fields: [
            {
              name: "PeggioramentoPatrimoniale",
              dependencies: ["Membrinucleo"],
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10cB",
          subtitle:
            "Qual è stata la rendita catastale dell'abitazione, inclusi cantina e garage, al 31 dicembre [year-1]?",
          guided: [
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.Mutuocasa === true && v.PeggioramentoPatrimoniale === true", "step":"10d_2B"},',
            '{"eval":true, "step":"10eB"}]',
          ],
          fields: [
            {
              name: `RenditaAbitazioneCorrente${year}`,
              dependencies: ["tipologiacasa", "PeggioramentoPatrimoniale"],
              placeholder: "Rendita catastale",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['RenditaAbitazioneCorrente${year}']) && v['RenditaAbitazioneCorrente${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              optional: false,
            },
          ],
        },
        {
          stepname: "10d_2B",
          subtitle: "Quanto rimaneva del mutuo per l’acquisto dell’abitazione al 31 dicembre [year-1]?",
          guided: [
            `Se non c'era un mutuo residuo al 31 dicembre [year-1] o se il mutuo è stato contratto dopo il [year-1], metti "0".`,
          ],
          nextstep: "10eB",
          fields: [
            {
              name: `MutuoRimanenteCorrente${year}`,
              dependencies: ["tipologiacasa", "Mutuocasa", "PeggioramentoPatrimoniale"],
              placeholder: "Mutuo residuo",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['MutuoRimanenteCorrente${year}']) && value.length > 0 && v['MutuoRimanenteCorrente${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              optional: false,
            },
          ],
        },
        {
          stepname: "10eB",
          subtitle:
            "%Nome%, esclusa la casa di abitazione, avevate immobili (fabbricati o terreni) al 31 dicembre [year-1]?",
          guided: [
            "Seleziona SI sia in caso di proprietà che in caso di diritto reale di godimento, cioè usufrutto, uso, abitazione, servitù, superficie ed enfiteusi (diritto reale su un fondo altrui). La locazione (affitto), la nuda proprietà e il comodato d'uso non rientrano in questa categoria.",
          ],
          yesno: true,
          nextstep: "10fB:10pB",
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale"],
              name: "ImmobiliterreniCorrente",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10fB",
          subtitle: "Seleziona ogni tipologia di immobile posseduta al 31 dicembre [year-1]:",
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliA2_C2_C6_C7_Corrente === true", "step":"10gB"},',
            '{"eval":"v.ImmobiliA_10_D5_Corrente === true", "step":"10hB"},',
            '{"eval":"v.ImmobiliC1_Corrente === true", "step":"10iB"},',
            '{"eval":"v.ImmobiliD_Corrente === true", "step":"10jB"},',
            '{"eval":"v.TerreniAgricoli_Corrente === true", "step":"10kB"},',
            '{"eval":"v.AreeEdificabili_Corrente === true", "step":"10lB"},',
            '{"eval":"v.ImmobiliEsteri_Corrente === true", "step":"10l2B"},',
            '{"eval":true, "step":"10pB"}',
            "]",
          ],
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente"],
              name: "ImmobiliA2_C2_C6_C7_Corrente",
              title:
                "Immobili ad uso abitativo diversi dalla casa di abitazione e relative pertinenze (gruppo catastale A, tranne A-10, e categorie catastali C-2, C-6 e C-7)",
              type: "checkbox",
            },
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente"],
              name: "ImmobiliA_10_D5_Corrente",
              title:
                "Uffici, studi privati o istituti di credito, cambio e assicurazione (categorie catastali A-10 e D-5)",
              type: "checkbox",
            },
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente"],
              name: "ImmobiliC1_Corrente",
              title: "Negozi o botteghe (categoria catastale C-1)",
              type: "checkbox",
            },
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente"],
              name: "ImmobiliD_Corrente",
              title:
                "Alberghi, opifici, teatri, cinema, case di cura, ospedali e fabbricati per esercizi sportivi o per le speciali esigenze di un'attività industriale, commerciale o agricola (gruppo catastale D, tranne D-5)",
              type: "checkbox",
            },
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente"],
              name: "TerreniAgricoli_Corrente",
              title: "Terreni agricoli o boschi",
              type: "checkbox",
            },
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente"],
              name: "AreeEdificabili_Corrente",
              title: "Aree edificabili",
              type: "checkbox",
            },
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente"],
              name: "ImmobiliEsteri_Corrente",
              title: "Immobili all'estero",
              type: "checkbox",
            },
          ],
        },
        {
          stepname: "10gB",
          subtitle:
            "Qual è la rendita catastale totale degli immobili ad uso abitativo diversi dalla casa di abitazione?",
          guided: [
            "Intendiamo il gruppo catastale A, tranne A-10, e le categorie catastali C-2, C-6 e C-7). Si tratta, ad esempio, di seconde case al mare o in montagna. Dovresti sommare le rendite catastali dei vari immobili posseduti dai membri del nucleo familiare.",
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliA_10_D5_Corrente === true", "step":"10hB"},',
            '{"eval":"v.ImmobiliC1_Corrente === true", "step":"10iB"},',
            '{"eval":"v.ImmobiliD_Corrente === true", "step":"10jB"},',
            '{"eval":"v.TerreniAgricoli_Corrente === true", "step":"10kB"},',
            '{"eval":"v.AreeEdificabili_Corrente === true", "step":"10lB"},',
            '{"eval":"v.ImmobiliEsteri_Corrente === true", "step":"10l2B"},',
            '{"eval":true, "step":"10m_2B"}',
            "]",
          ],
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente", "ImmobiliA2_C2_C6_C7_Corrente"],
              name: `RenditaA2_C2_C6_C7_Corrente${year}`,
              type: "currency",
              placeholder: "Rendita catastale",
              validation: "eval",
              expression: `!isNaN(v['RenditaA2_C2_C6_C7_Corrente${year}']) && v['RenditaA2_C2_C6_C7_Corrente${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10hB",
          subtitle:
            "Qual è la rendita catastale totale di uffici, studi privati o istituti di credito, cambio e assicurazione",
          guided: [
            "Intendiamo le categorie catastali A-10 e D-5. Dovresti sommare le rendite catastali dei vari immobili posseduti dai membri del nucleo familiare.",
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliC1_Corrente === true", "step":"10iB"},',
            '{"eval":"v.ImmobiliD_Corrente === true", "step":"10jB"},',
            '{"eval":"v.TerreniAgricoli_Corrente === true", "step":"10kB"},',
            '{"eval":"v.AreeEdificabili_Corrente === true", "step":"10lB"},',
            '{"eval":"v.ImmobiliEsteri_Corrente === true", "step":"10l2B"},',
            '{"eval":true, "step":"10m_2B"}]',
          ],
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente", "ImmobiliA_10_D5_Corrente"],
              name: `RenditaA_10_D5_Corrente${year}`,
              type: "currency",
              placeholder: "Rendita catastale",
              validation: "eval",
              expression: `!isNaN(v['RenditaA_10_D5_Corrente${year}']) && v['RenditaA_10_D5_Corrente${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10iB",
          subtitle: "Qual è la rendita catastale totale di negozi e botteghe?",
          guided: [
            "Intendiamo la categoria catastale C-1. Dovresti sommare le rendite catastali dei vari immobili posseduti dai membri del nucleo familiare.",
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliD_Corrente === true", "step":"10jB"},',
            '{"eval":"v.TerreniAgricoli_Corrente === true", "step":"10kB"},',
            '{"eval":"v.AreeEdificabili_Corrente === true", "step":"10lB"},',
            '{"eval":"v.ImmobiliEsteri_Corrente === true", "step":"10l2B"},',
            '{"eval":true, "step":"10m_2B"}]',
          ],
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente", "ImmobiliC1_Corrente"],
              name: `RenditaC1_Corrente${year}`,
              type: "currency",
              placeholder: "Rendita catastale",
              validation: "eval",
              expression: `!isNaN(v['RenditaC1_Corrente${year}']) && v['RenditaC1_Corrente${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10jB",
          subtitle:
            "Qual è la rendita catastale totale di alberghi, opifici, teatri, cinema, case di cura, ospedali e fabbricati per esercizi sportivi o per le speciali esigenze di un’attività industriale, commerciale o agricola?",
          guided: [
            "Intendiamo il gruppo catastale D, tranne D-5. Dovresti sommare le rendite catastali dei vari immobili posseduti dai membri del nucleo familiare.",
            "Trovi questo valore:",
            "• all'interno della visura catastale dell'immobile;",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro B, sezione I, nel rigo dell'immobile, colonna 1.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
            "",
            "Pssst, se vuoi possiamo farti avere la visura catastale per ogni membro del nucleo, ma ne parliamo poi ;)",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.TerreniAgricoli_Corrente === true", "step":"10kB"},',
            '{"eval":"v.AreeEdificabili_Corrente === true", "step":"10lB"},',
            '{"eval":"v.ImmobiliEsteri_Corrente === true", "step":"10l2B"},',
            '{"eval":true, "step":"10m_2B"}]',
          ],
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente", "ImmobiliD_Corrente"],
              name: `RenditaD_Corrente${year}`,
              type: "currency",
              placeholder: "Rendita catastale",
              validation: "eval",
              expression: `!isNaN(v['RenditaD_Corrente${year}']) && v['RenditaD_Corrente${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10kB",
          subtitle: "Qual è il reddito dominicale totale dei terreni agricoli o boschivi?",
          guided: [
            "Il reddito dominicale è un valore che puoi trovare all'interno della visura catastale del terreno.",
            "Se possiedi solo una parte del reddito, indicaci la rendita catastale della tua parte (es. Rendita catastale = 200, quota = 50%, metti 100).",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.AreeEdificabili_Corrente === true", "step":"10lB"},',
            '{"eval":"v.ImmobiliEsteri_Corrente === true", "step":"10l2B"},',
            '{"eval":true, "step":"10m_2B"}]',
          ],
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente", "TerreniAgricoli_Corrente"],
              name: `RenditaTerreniAgricoli_Corrente${year}`,
              type: "currency",
              placeholder: "Reddito dominicale",
              validation: "eval",
              expression: `!isNaN(v['RenditaTerreniAgricoli_Corrente${year}']) && v['RenditaTerreniAgricoli_Corrente${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10lB",
          subtitle: "Qual è il valore ai fini IMU delle aree edificabili?",
          guided: [
            "Non lo conosci? Chiedi pure al tuo commercialista e continua più tardi ;)",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'area edificabile, indica il valore IMU per la parte posseduta (es. valore IMU = 40.000, quota = 50%, indicare 20.000). Vanno sommati i valori IMU delle varie aree edificabili possedute dai membri del nucleo familiare.",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v.ImmobiliEsteri_Corrente === true", "step":"10l2B"},',
            '{"eval":true, "step":"10m_2B"}]',
          ],
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente", "AreeEdificabili_Corrente"],
              name: `RenditaAreeEdificabili_Corrente${year}`,
              type: "currency",
              placeholder: "Valore IMU",
              validation: "eval",
              expression: `!isNaN(v['RenditaAreeEdificabili_Corrente${year}']) && v['RenditaAreeEdificabili_Corrente${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10l2B",
          subtitle: "Qual è il valore ai fini IVIE degli immobili posseduti all'estero?",
          guided: [
            "IVIE: imposta sul valore degli immobili situati all’estero. Il valore ai fini IVIE per i Paesi appartenenti alla Unione Europea, Norvegia e Islanda è quello catastale. In mancanza del valore catastale, si fa riferimento al costo dell'immobile che risulta dall’atto di acquisto o, in assenza, al valore di mercato. Per gli altri Paesi, il valore dell’immobile è costituito dal costo risultante dall'atto di acquisto o, in assenza, dal valore di mercato. Per gli immobili acquisiti per successione o donazione, il valore è quello dichiarato nella dichiarazione di successione o negli altri atti previsti dagli ordinamenti esteri con finalità analoghe.",
            "Puoi trovare questo valore nella dichiarazione dei REDDITI Persone Fisiche [year-1], relativo ai redditi [year-2], nel quadro RW, nel rigo dove è indicato l'immobile, riquadro 8.",
            "",
            "Se uno o più componenti del nucleo possiedono solo una parte dell'immobile, indica il valore IVIE per la parte posseduta (es. valore IVIE = 10.000, quota = 50%, indicare 5.000). Vanno sommati i valori IVIE dei vari immobili all'estero posseduti dai membri del nucleo familiare.",
          ],
          nextstep: "10m_2B",
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente", "ImmobiliEsteri_Corrente"],
              name: `RenditaImmobiliEsteri_Corrente${year}`,
              type: "currency",
              placeholder: "Valore IVIE",
              validation: "eval",
              expression: `!isNaN(v['RenditaImmobiliEsteri_Corrente${year}']) && v['RenditaImmobiliEsteri_Corrente${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10m_2B",
          subtitle: "Uno o più componenti ha acquistato uno dei fabbricati o dei terreni indicati con un mutuo?",
          nextstep: "10mB:10pB",
          yesno: true,
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente"],
              name: "MutuoAltro_Corrente",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10mB",
          subtitle: "Qual è la quota totale rimanente dei mutui al 31 dicembre [year-1]?",
          guided: [
            "Se non c'era un mutuo residuo al 31 dicembre [year-1] o se il mutuo è stato contratto dopo il [year-1], indica: 0.",
          ],
          nextstep: "10pB",
          fields: [
            {
              dependencies: ["PeggioramentoPatrimoniale", "ImmobiliterreniCorrente", "MutuoAltro_Corrente"],
              name: `QuotaMutuoAltro_Corrente${year}`,
              type: "currency",
              placeholder: "Mutuo residuo",
              validation: "eval",
              expression: `!isNaN(v['QuotaMutuoAltro_Corrente${year}']) && value.length > 0 && v['QuotaMutuoAltro_Corrente${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              optional: false,
            },
          ],
        },
        {
          stepname: "10pB",
          subtitle:
            "Indica il valore totale del patrimonio mobiliare (ad esempio: carte prepagate, conti in banca, azioni, buoni fruttiferi) del nucleo familiare al 31 dicembre [year-1]:",
          guided: [
            "Si tratta del valore totale di conti corrente, conti deposito, carte prepagate, titoli di stato, azioni, obbligazioni, buoni fruttiferi e altri strumenti finanziari posseduti. In caso di conti o altri rapporti finanziari cointestati con persone esterne al nucleo familiare, indica la quota posseduta dal nucleo.",
            "Per i conti e le carte prepagate, considera il saldo al 31 dicembre [year-1] (ovvero quanto avevi sul conto o la carta a fine anno).",
          ],
          nextstep: "finish",
          fields: [
            {
              name: `PatrimonioMobiliare_Corrente${year}`,
              dependencies: ["Membrinucleo", "PeggioramentoPatrimoniale"],
              placeholder: "Valore patrimonio mobiliare",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['PatrimonioMobiliare_Corrente${year}']) && value.length > 0 && v['PatrimonioMobiliare_Corrente${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              optional: false,
            },
          ],
        },
      ],
    },
  ],
};

export default patrimonio;
