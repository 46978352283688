import axios from "axios";
import { getAxiosPyrgosConfig } from "../../../../common/pyrgos";
import { IApiClientService } from "../../../../services/ApiClient/ApiClientService.interface";
import AxiosApiClientInstance from "../../../../services/AxiosApiClientInstance/AxiosApiClientInstance";
import { Mapper } from "../../../../utils/mapper";
import { ChatbotResponseDTO } from "../../dtos/chatbot-response.dto";
import { ChatbotUserMessageDto } from "../../dtos/chatbot-user-message.dto";
import { IAssistantRemoteDataSource } from "./assistant-remote.datasource.interface";

export class AssistantRemoteDataSource implements IAssistantRemoteDataSource {
  private readonly _OPTIONS = getAxiosPyrgosConfig();
  private readonly _ASK_TO_CHATBOT_URL = "/assistants/ask-within-session";

  constructor(private readonly _client: IApiClientService = new AxiosApiClientInstance(axios)) {}

  public async askToChatbot(userMessage: ChatbotUserMessageDto): Promise<ChatbotResponseDTO> {
    const { data } = await this._client.post<ChatbotUserMessageDto, ChatbotResponseDTO>(
      this._ASK_TO_CHATBOT_URL,
      userMessage,
      this._OPTIONS
    );

    if (!data) {
      throw new Error("Chatbot response not available");
    }

    const chatResponseDto = Mapper.mapData(ChatbotResponseDTO, data);
    return chatResponseDto;
  }
}
