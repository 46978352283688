import * as Sentry from "@sentry/react";
import { getEnvName } from "../utils/getEnvName";

function getSampleRateLevel(envName?: string) {
  if (envName?.includes("local")) return 0;
  if (envName?.includes("develop")) return 1;
  if (envName?.includes("staging")) return 0.5;
  if (envName?.includes("prod")) return 0.05;

  return 0;
}

function getReplaySampleRateLevel(envName?: string) {
  if (envName?.includes("local")) return 0;
  if (envName?.includes("develop")) return 0;
  if (envName?.includes("staging")) return 0;
  if (envName?.includes("prod")) return 0.01;

  return 0;
}

function getReplayOnErrorSampleRateLevel(envName?: string) {
  if (envName?.includes("local")) return 0;
  if (envName?.includes("develop")) return 0;
  if (envName?.includes("staging")) return 1;
  if (envName?.includes("prod")) return 0.5;

  return 0;
}

const envName = getEnvName();
const sampleRateLevel = getSampleRateLevel(envName);
const replayRateLevel = getReplaySampleRateLevel(envName);
const replayOnErrorRateLevel = getReplayOnErrorSampleRateLevel(envName);

const ignoreErrors = [
  /fb_xd_fragment/gim,
  /findDOMNode/gim,
  /ResizeObserver/gim,
  /UNSAFE_componentWillMount/gim,
  /Illegal/gim,
  /Network Error/gim,
];

if (envName.includes("prod")) {
  ignoreErrors.push(/DevCycle/gim);
}

export const sentryConfig: Sentry.BrowserOptions = {
  release: `bx-feapp-platform@${process.env.npm_package_version}`,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.extraErrorDataIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  sampleRate: 1,
  tracesSampleRate: sampleRateLevel,
  replaysSessionSampleRate: replayRateLevel,
  replaysOnErrorSampleRate: replayOnErrorRateLevel,
  environment: process.env.REACT_APP_ENV_NAME,
  enabled: envName !== "local",
  autoSessionTracking: envName !== "local",
  ignoreErrors,
  beforeSend(event) {
    if (window.hj && event.level === "error") {
      window.hj("event", "error_occurred");
    }
    return event;
  },
};
