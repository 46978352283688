import { AxiosError, AxiosResponse } from "axios";
import isUndefined from "lodash/isUndefined";
import { AxiosApiClientResponse, IApiClientResponse } from "./AxiosApiClientResponse";

export interface IApiClientError<Data> {
  code?: string;
  request?: any;
  response?: IApiClientResponse<Data>;
}

export class AxiosApiClientError<Data> implements IApiClientError<Data> {
  public code?: string;
  public request?: any;
  public response?: IApiClientResponse<Data>;

  constructor(error: AxiosError<Data>) {
    this.code = error?.code;
    this.request = error?.request;

    if (this._isValidResponse(error.response)) {
      const axiosResponse: AxiosResponse = {
        data: error.response.data,
        status: error.response.status,
        statusText: error.response.statusText,
        headers: error.response.headers,
        request: error.response.request,
        config: error.response.config,
      };
      this.response = new AxiosApiClientResponse(axiosResponse);
    }
  }

  private _isValidResponse(response?: AxiosResponse): response is AxiosResponse {
    return !isUndefined(response);
  }
}
