import { ClientConstantsCacheDatasource } from "../datasources/cached/client-constants-cached.datasource";
import { IClientConstantCachedDatasource } from "../datasources/cached/client-constants-cached.datasource.interface";
import { ClientConstantsRemoteDatasource } from "../datasources/remote/client-constants-remote.datasource";
import { IClientConstantsRemoteDatasource } from "../datasources/remote/client-constants-remote.datasource.interface";
import { ClientConstantCmsDto } from "../dtos/client-constant.dto";
import { IClientConstantsRepository } from "./client-constants.repository.interface";

export class ClientConstantsRepository implements IClientConstantsRepository {
  constructor(
    private readonly _remoteCmsDataSource: IClientConstantsRemoteDatasource = new ClientConstantsRemoteDatasource(),
    private readonly _cachedCmsDataSource: IClientConstantCachedDatasource = new ClientConstantsCacheDatasource()
  ) {}

  async fetchAllClientConstantsFromRemote(): Promise<void> {
    const response = await this._remoteCmsDataSource.getAllClientConstants();
    this._cachedCmsDataSource.populateClientConstantsCache(response);
  }

  async getValueFromRemote(constantKey: string): Promise<ClientConstantCmsDto> {
    const response = await this._remoteCmsDataSource.getClientConstantValue(constantKey);
    this._cachedCmsDataSource.setClientConstantValue(response);

    return response;
  }

  getValueFromCache(constantKey: string): ClientConstantCmsDto | undefined {
    return this._cachedCmsDataSource.getClientConstantValue(constantKey);
  }
}
