import { Expose } from "class-transformer";

export interface IConversationDTO {
  sessionId: string;
  owner: number;
}

export class ConversationDTO implements IConversationDTO {
  @Expose()
  sessionId!: string;

  @Expose()
  owner!: number;
}
