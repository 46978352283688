import { Expose } from "class-transformer";

interface IClientConstantCmsDto {
  constantValue: string;
  constantName: string;
}

export class ClientConstantCmsDto implements IClientConstantCmsDto {
  @Expose({ name: "CONSTANT_VALUE" })
  constantValue!: string;

  @Expose({ name: "CONSTANT_NAME" })
  constantName!: string;
}
