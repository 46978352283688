import { AssistantRemoteDataSource } from "../../datasources/assistant/assistant-remote.datasource";
import { IAssistantRemoteDataSource } from "../../datasources/assistant/assistant-remote.datasource.interface";
import { ChatbotResponseDTO } from "../../dtos/chatbot-response.dto";
import { ChatbotUserMessageDto } from "../../dtos/chatbot-user-message.dto";
import { IChatbotRepository } from "./chatbot.repository.interface";

export class ChatbotRepository implements IChatbotRepository {
  constructor(private readonly _remoteDataSource: IAssistantRemoteDataSource = new AssistantRemoteDataSource()) {}

  async askToChatbot(userMessage: ChatbotUserMessageDto): Promise<ChatbotResponseDTO> {
    const chatbotResponse = await this._remoteDataSource.askToChatbot(userMessage);
    return chatbotResponse;
  }
}
