import { Expose } from "class-transformer";

export enum ChatbotMessageSenderType {
  USER = "USER",
  ASSISTANT = "ASSISTANT",
}

export interface ISessionMessageDTO {
  id: number;
  sessionId: string;
  senderType: ChatbotMessageSenderType;
  message: string;
}

export class SessionMessageDTO implements ISessionMessageDTO {
  @Expose()
  id!: number;

  @Expose()
  sessionId!: string;

  @Expose()
  senderType!: ChatbotMessageSenderType;

  @Expose()
  message!: string;
}
