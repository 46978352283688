import { IProduct } from "../entities/product.entity.interface";
import { ProductsAvailabilities } from "../entities/products-availabilities.entity";
import { ProductsRepository } from "../repositories/products.repository";
import { IProductsRepository } from "../repositories/products.repository.interface";
import { IProductsService } from "./products.service.interface";

export class ProductsService implements IProductsService {
  constructor(private readonly _productsRepository: IProductsRepository = new ProductsRepository()) {}

  resetProductsWithAvailability(): void {
    this._productsRepository.resetAllProductsCache();
  }

  getProductsWithAvailability(): Promise<ProductsAvailabilities> {
    const products = this._productsRepository.getAllProducts();

    return products;
  }

  getProductsByUniqueIdentifiers(uniqueIdentifiers: string[]): Promise<ProductsAvailabilities> {
    const products = this._productsRepository.getProductsByUniqueIdentifiers(uniqueIdentifiers);

    return products;
  }

  async getProductsCollection(): Promise<IProduct[]> {
    const { productsAvailable, productsNotAvailable } = await this._productsRepository.getAllProducts();

    const products: IProduct[] = [...productsAvailable, ...productsNotAvailable];

    return products;
  }
}
