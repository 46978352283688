import axios from "axios";
import { getBonusxCmsConfig } from "../../../../common/bonusxCmsConfig";
import { CmsDataResponse } from "../../../../types/cms-data-response.type";
import { Mapper } from "../../../../utils/mapper";
import { IApiClientService } from "../../../ApiClient/ApiClientService.interface";
import AxiosApiClientInstance from "../../../AxiosApiClientInstance/AxiosApiClientInstance";
import { ClientConstantCmsDto } from "../../dtos/client-constant.dto";
import { IClientConstantsRemoteDatasource } from "./client-constants-remote.datasource.interface";

export class ClientConstantsRemoteDatasource implements IClientConstantsRemoteDatasource {
  private readonly _OPTIONS = getBonusxCmsConfig();
  private readonly locale = "it";
  private readonly _MAX_PAGE_SIZE = 1_000;
  private readonly _REQUEST_TIMEOUT_SECONDS = 2;
  private readonly _REQUEST_TIMEOUT_MILLISECONDS = this._REQUEST_TIMEOUT_SECONDS * 1000;
  private readonly _GET_CLIENT_CONSTANTS_URL = `/api/client-constants?populate=*&pagination[pageSize]=${this._MAX_PAGE_SIZE}&locale=${this.locale}`;

  constructor(private readonly _client: IApiClientService = new AxiosApiClientInstance(axios)) {}

  async getAllClientConstants(): Promise<ClientConstantCmsDto[]> {
    const { data } = await this._client.get<CmsDataResponse<ClientConstantCmsDto>>(this._GET_CLIENT_CONSTANTS_URL, {
      ...this._OPTIONS,
      timeout: this._REQUEST_TIMEOUT_MILLISECONDS,
    });

    if (!data || !data.data || data.data.length === 0) {
      throw new Error("Client constants not available");
    }

    const attributes = data.data.flatMap(function flatData(cmsObject) {
      return cmsObject.attributes;
    });

    const mappedDtos: ClientConstantCmsDto[] = Mapper.mapArrayData(ClientConstantCmsDto, attributes);

    return mappedDtos;
  }

  async getClientConstantValue(key: string): Promise<ClientConstantCmsDto> {
    const { data } = await this._client.get<CmsDataResponse<ClientConstantCmsDto>>(
      this._generateFilterUrl(key),
      this._OPTIONS
    );

    if (!data || !data.data || data.data.length === 0) {
      throw new Error("Client constant not available");
    }

    const attributes = data.data.flatMap(function flatData(cmsObject) {
      return cmsObject.attributes;
    });

    const mappedDto = Mapper.mapData(ClientConstantCmsDto, attributes[0]);

    return mappedDto;
  }

  private _generateFilterUrl(key: string) {
    return this._GET_CLIENT_CONSTANTS_URL + `&filters[$and][0][CONSTANT_NAME][$eq]=${key}`;
  }
}
