import { AxiosResponse } from "axios";
import { Account } from "bonusx-api-main-manager";
import useSWR from "swr";
import { swrBaseConfig } from "../common/swrBaseConfig";
import { accountApi } from "../services/main-account-api-instance";
import { useAbility } from "./useAbilityRules";
import { useIsLogged } from "./useIsLogged";

export const useAccountSwrKey = "account-get-me";

interface IUseAccount {
  accountData: Account | undefined;
  revalidate(): Promise<AxiosResponse<Account> | undefined>;
  loading: boolean;
  error: any;
  accountApi: typeof accountApi;
}

function fetcher() {
  return accountApi.getMe();
}

export function useAccount(): IUseAccount {
  const isLogged = useIsLogged();
  const abilityRules = useAbility();
  const abilited = abilityRules.can("readOne", "Account");
  const skip = !isLogged || !abilited;

  const currentKey = skip ? null : useAccountSwrKey;
  const response = useSWR<AxiosResponse<Account>>(currentKey, fetcher, swrBaseConfig);

  let accountData: Account | undefined = response.data?.data;

  async function revalidate() {
    const data = await fetcher();
    return await response.mutate(data);
  }

  return {
    accountData,
    revalidate,
    loading: response.isValidating,
    error: response.error,
    accountApi,
  };
}
