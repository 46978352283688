import { IApiClientConfig } from "../services/AxiosApiClientInstance/dtos/AxiosApiClientConfig";

export function getBonusxApiConfigV1(): IApiClientConfig {
  return {
    baseURL: `${process.env.REACT_APP_MAIN_URL || ""}/sapi/api/v1`,
    withCredentials: true,
  };
}

export function getBonusxApiConfigV2(): IApiClientConfig {
  return {
    baseURL: `${process.env.REACT_APP_MAIN_URL || ""}/sapi/api/v2`,
    withCredentials: true,
  };
}

export function getBonusxApiConfigV3(): IApiClientConfig {
  return {
    baseURL: `${process.env.REACT_APP_MAIN_URL || ""}/sapi/api/v3`,
    withCredentials: true,
  };
}

export function getBonusxAdminApiConfigV2(): IApiClientConfig {
  return {
    baseURL: `${process.env.REACT_APP_MAIN_URL || ""}/sadm/api/v2`,
    withCredentials: true,
  };
}
