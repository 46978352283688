import { Expose } from "class-transformer";

interface IChatbotResponseDTO {
  textMessage: string;
}

export class ChatbotResponseDTO implements IChatbotResponseDTO {
  @Expose()
  textMessage!: string;
}
