import { AxiosResponse } from "axios";
import { Account } from "bonusx-api-main-manager";
import isEqual from "lodash/isEqual";
import { createStore } from "redux";
import { Subject } from "rxjs";
import { distinctUntilChanged, mergeMap, throttleTime } from "rxjs/operators";
import { QuestionnaireName } from "./enums/QuestionnaireName.enum";
import rootReducer from "./reducers";
import { accountApi } from "./services/main-account-api-instance";
import { handleQuestionnaire } from "./stores/questionnaire/handleQuestionnaire";
import { questionnaireSubject$ } from "./stores/questionnaireSubject";
import { serializeForDiffCheck } from "./utils/serializeForDiffCheck";

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export async function saveQuestionnaireDataToApi(
  prevState: RootStoreModule.State
): Promise<void | AxiosResponse<Account>> {
  const { formValues, currentForm } = { ...prevState };

  const mainActivity = formValues["AttivitaPrincipale"];
  const city = formValues["applicant_PosizioneResidenza"];

  if (!mainActivity || !city || currentForm !== QuestionnaireName.Citizenship) return Promise.resolve();

  const editProfilePayload = {
    mainActivity,
    city,
  };

  return await accountApi.editProfile(editProfilePayload);
}

function subscribeToQuestionnairePersist(questionnaireSubject$: Subject<RootStoreModule.State>) {
  console.debug("Questionnaire start listen");

  const questionnairePersist$ = questionnaireSubject$.pipe(
    throttleTime(500, undefined, { trailing: true }),
    distinctUntilChanged(function isDiffState(previous, current) {
      const isTheSame = isEqual(serializeForDiffCheck(previous.formValues), serializeForDiffCheck(current.formValues));

      return isTheSame;
    }),
    mergeMap(async (data) => {
      const [questionnaireResult, profileResult] = await Promise.all([
        handleQuestionnaire(data),
        saveQuestionnaireDataToApi(data),
      ]);

      return { questionnaireResult, profileResult };
    })
  );

  questionnairePersist$.subscribe({
    next: function afterQuestionnaireIsStored(data) {
      console.debug("Questionnaire response:", data);

      console.debug("Reset products list cache");
    },
    error: (error) => {
      console.error("Questionnaire error:", error);
      subscribeToQuestionnairePersist(questionnaireSubject$);
    },
    complete: () => {
      console.debug("Questionnaire stop listen");
      subscribeToQuestionnairePersist(questionnaireSubject$);
    },
  });
}

subscribeToQuestionnairePersist(questionnaireSubject$);

store.subscribe(function onSetQuestionnaire() {
  const currentState = store.getState().updates;

  questionnaireSubject$.next(currentState);
});

export default store;
