export function validate(forms, values) {
  const result = [];
  forms.forms.map((v, k) => {
    v.values.fields.forEach((item, i) => {
      if (!item.optional) {
        const value = values[item.name];
        switch (item.validation) {
          case "has":
            if (!value || (value && value.length < 1)) {
              result.push({ name: item.name });
            }
            break;
          case "number":
            if (!value || (value && isNaN(value))) {
              result.push({ name: item.name });
            }
            break;
          case "selected":
            if (!value || (value && value.length < 1)) {
              result.push({ name: item.name });
            }
            break;
        }
      }
    });
  });

  return result;
}

export function getValuesFromName(values, name) {
  let v = values[name];
  return v;
}

export function nextStep(step, values) {
  switch (step) {
    case 1:
      if (values[0].FirstName == "Emilio") {
        return step + 1;
      } else {
        return 0;
      }
      break;
    default:
      return step + 1;
  }
}

export function checkForStep(operator, value, control, stepTrue, stepFalse) {
  // Example "nextstep": "check:<:Membrinucleo:2:f9:f1",
  switch (operator) {
    case "<":
      if (value < control) {
        return stepTrue;
      } else {
        return stepFalse;
      }
      break;
    default:
      console.log("ATTENTION!!! Default check function value.");
      return stepFalse;
  }
}

export function switchForStep(v, text, response) {
  const cases = JSON.parse(text);
  for (let i = 0; i < cases.length; i++) {
    if (eval(cases[i].eval)) return cases[i].step;
  }
  console.log("Problem here...at least one should be true.");
}

export function getIdFromForm(forms, name) {
  let id = 0;

  forms.forEach((item, i) => {
    if (item.name === name) {
      id = i;
    }
  });

  return id;
}

export function getFormByName(forms, name) {
  const id = getIdFromForm(forms, name);
  return forms[id];
}

export function getNextForm(forms, current, direction) {
  const id = getIdFromForm(forms, current);
  if (direction === "FORWARD") {
    if (id + 1 >= forms.length) return forms[id];
    return forms[id + 1];
  }

  if (direction === "BACKWARD") {
    if (id === 0) return forms[id];
    return forms[id - 1];
  }

  return forms[id];
}

export function getFieldsFromStep(forms, activeStepName) {
  for (const form of forms) {
    for (const section of form.sections) {
      const step = section.forms.find((form) => form.stepname === activeStepName);
      if (step) return step.fields;
    }
  }

  return [];
}

export function openLinkSupportCtrl(linkInput, event) {
  if (event.ctrlKey || event.metaKey) {
    if (linkInput) window.open(linkInput || "", "_blank", "noopener noreferrer");
    return true;
  }
  return false;
}
