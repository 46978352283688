import { AI_CHATBOT_ACTIONS } from "./chatbot.reducer.enum";
import { ChatbotAction, ChatbotState } from "./chatbot.reducer.type";

export const chatbotInitialState: ChatbotState = {
  messages: [],
  isSending: false,
  showEmptyState: false,
  scrollToBottom: false,
  showScrollToBottom: false,
  isCreatingNewSession: false,
  canCloneFromPreviousChat: false,
};

export function chatbotReducer(state: ChatbotState, action: ChatbotAction): ChatbotState {
  const updatedMessagesArray = [...state.messages];
  if (action.messagePayloadValue) {
    updatedMessagesArray.push(action.messagePayloadValue);
  }

  switch (action.type) {
    case AI_CHATBOT_ACTIONS.POPULATE_CHAT_MESSAGES:
      return {
        ...state,
        messages: action.messagesArrayPayloadValue!,
      };
    case AI_CHATBOT_ACTIONS.ADD_USER_MESSAGE_ACTION:
      return {
        ...state,
        messages: updatedMessagesArray,
      };
    case AI_CHATBOT_ACTIONS.ADD_AI_RESPONSE_ACTION:
      return {
        ...state,
        messages: updatedMessagesArray,
      };
    case AI_CHATBOT_ACTIONS.SET_IS_SENDING_ACTION:
      return {
        ...state,
        isSending: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_SHOW_EMPTY_STATE_ACTION:
      return {
        ...state,
        showEmptyState: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_SCROLL_TO_BOTTOM_ACTION:
      return {
        ...state,
        scrollToBottom: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_SHOW_SCROLL_TO_BOTTOM_BUTTON_ACTION:
      return {
        ...state,
        showScrollToBottom: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_IS_STREAMING_RESPONSE:
      return {
        ...state,
        isStreamingResponse: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_IS_CREATING_NEW_SESSION:
      return {
        ...state,
        isCreatingNewSession: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.CLEAR_CHAT_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    case AI_CHATBOT_ACTIONS.SET_CAN_CLONE_FROM_PREVIOUS_CHAT:
      return {
        ...state,
        canCloneFromPreviousChat: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_IS_CLONING_FROM_PREVIOUS_CHAT:
      return {
        ...state,
        isCloningFromPreviousChat: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.RESET:
      return {
        ...chatbotInitialState,
      };
    default:
      return state;
  }
}
