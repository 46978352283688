import axios, { AxiosError, AxiosInstance, AxiosStatic } from "axios";

import { IApiClientRequestConfigOptions } from "../ApiClient/ApiClientRequestConfigOptions.interface";
import { IApiClientService } from "../ApiClient/ApiClientService.interface";
import { IApiClientConfig } from "./dtos/AxiosApiClientConfig";
import { AxiosApiClientError } from "./dtos/AxiosApiClientError";
import { AxiosApiClientResponse, IApiClientResponse } from "./dtos/AxiosApiClientResponse";

export class AxiosApiClientInstance implements IApiClientService {
  private _clientInstance: AxiosInstance;
  private _client: AxiosStatic;
  constructor(client: AxiosStatic = axios, config?: IApiClientConfig) {
    this._client = client;
    this._clientInstance = client.create(config);
  }

  public async get<Data>(url: string, options: IApiClientRequestConfigOptions): Promise<IApiClientResponse<Data>> {
    try {
      const responseFromAxios = await this._clientInstance.get<Data>(url, options);
      const response = new AxiosApiClientResponse<Data>(responseFromAxios);

      return response;
    } catch (errorFromAxios) {
      if (this._isValidAxiosError(errorFromAxios)) {
        const error = new AxiosApiClientError<Data>(errorFromAxios);
        return Promise.reject(error);
      }

      throw errorFromAxios;
    }
  }

  public async post<Data, ResponseData>(
    url: string,
    body: Data,
    options: IApiClientRequestConfigOptions
  ): Promise<IApiClientResponse<ResponseData>> {
    try {
      const responseFromAxios = await this._clientInstance.post<ResponseData>(url, body, options);
      const response = new AxiosApiClientResponse<ResponseData>(responseFromAxios);

      return response;
    } catch (errorFromAxios) {
      if (this._isValidAxiosError(errorFromAxios)) {
        const error = new AxiosApiClientError<Data>(errorFromAxios);
        return Promise.reject(error);
      }

      throw errorFromAxios;
    }
  }

  public async patch<Data, ResponseData>(
    url: string,
    body: Data,
    options: IApiClientRequestConfigOptions
  ): Promise<IApiClientResponse<ResponseData>> {
    try {
      const responseFromAxios = await this._clientInstance.patch<ResponseData>(url, body, options);
      const response = new AxiosApiClientResponse<ResponseData>(responseFromAxios);

      return response;
    } catch (errorFromAxios) {
      if (this._isValidAxiosError(errorFromAxios)) {
        const error = new AxiosApiClientError<Data>(errorFromAxios);
        return Promise.reject(error);
      }

      throw errorFromAxios;
    }
  }

  private _isValidAxiosError(error: unknown): error is AxiosError {
    return this._client.isAxiosError(error);
  }
}

export default AxiosApiClientInstance;
