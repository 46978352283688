import { Expose } from "class-transformer";

export interface IConversation {
  sessionId: string;
  owner: number;
}

export class Conversation implements IConversation {
  @Expose()
  sessionId!: string;

  @Expose()
  owner!: number;
}
