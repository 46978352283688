import { ClientConstantsService } from "../../../services/client-constants/client-constants.service";
import { IClientConstantsService } from "../../../services/client-constants/client-constants.service.interface";
import { ChatbotUserMessageDto } from "../dtos/chatbot-user-message.dto";
import { IChatbotMessage } from "../entities/chatbot-message.entity";
import { ChatbotRepository } from "../repositories/chatbot/chatbot.repository";
import { IChatbotRepository } from "../repositories/chatbot/chatbot.repository.interface";
import { ConversationRepository } from "../repositories/conversation/conversation.repository";
import { IConversationRepository } from "../repositories/conversation/conversation.repository.interface";
import { AskToChatbotParams, IChatbotService } from "./chatbot.service.interface";

export class ChatbotService implements IChatbotService {
  public static readonly DEFAULT_AI_RESPONSE = "Scusa, non ho capito. Puoi ripetere?";
  private readonly _DEFAULT_RESPONSE_KEY = "AI_DEFAULT_FALLBACK_MESSAGE";

  constructor(
    private readonly _chatbotRepository: IChatbotRepository = new ChatbotRepository(),
    private readonly _chatbotRemoteFallbackResponseService: IClientConstantsService = ClientConstantsService.instance,
    private readonly _conversationRepository: IConversationRepository = new ConversationRepository()
  ) {}

  public async createNewSession(): Promise<boolean> {
    try {
      await this._conversationRepository.createNewSession();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async getActiveSessionMessages(): Promise<IChatbotMessage[]> {
    try {
      const lastSession = await this._conversationRepository.getLastSession();
      if (!lastSession.isActive) {
        return [];
      }
      const oldMessages = await this._conversationRepository.getAllMessagesForSession(lastSession.id);
      return oldMessages;
    } catch (error) {
      return [];
    }
  }

  public async askToChatbot({ message, metadata, fallbackResponse }: Readonly<AskToChatbotParams>): Promise<string> {
    try {
      const userMessageDto: ChatbotUserMessageDto = new ChatbotUserMessageDto();
      userMessageDto.question = message;
      userMessageDto.metadata = metadata;

      const result = await this._chatbotRepository.askToChatbot(userMessageDto);
      return result.textMessage;
    } catch (error) {
      if (fallbackResponse && fallbackResponse.length > 0) {
        return fallbackResponse;
      }

      const defaultFallbackResponse = this._getFallbackResponse();
      return defaultFallbackResponse;
    }
  }

  private _getFallbackResponse(): string {
    const fallbackResponse = this._chatbotRemoteFallbackResponseService.getClientConstantValueOrDefault({
      key: this._DEFAULT_RESPONSE_KEY,
      default: ChatbotService.DEFAULT_AI_RESPONSE,
    });

    return fallbackResponse;
  }

  public async hasPreviousConversations(): Promise<boolean> {
    const conversations = await this._conversationRepository.getAllConversations();
    const hasPreviousConversations = conversations.length > 0;

    return hasPreviousConversations;
  }

  public async cloneFromPreviousChat(): Promise<boolean> {
    try {
      await this._conversationRepository.cloneFromPreviousChat();
      return true;
    } catch (error) {
      return false;
    }
  }
}
