import { Expose } from "class-transformer";

interface IChatbotUserMessageMetadata {
  [key: string]: string;
}

interface IChatbotUserMessageDto {
  question: string;
  metadata?: IChatbotUserMessageMetadata;
}

export class ChatbotUserMessageDto implements IChatbotUserMessageDto {
  @Expose()
  question!: string;

  @Expose()
  metadata?: IChatbotUserMessageMetadata;
}
