import { IProduct } from "./product.entity.interface";
import { IProductsAvailabilities } from "./products-availabilities.entity.interface";

export class ProductsAvailabilities implements IProductsAvailabilities {
  productsAvailable: IProduct[];
  productsNotAvailable: IProduct[];

  constructor(productsAvailable: IProduct[], productsNotAvailable: IProduct[]) {
    this.productsAvailable = productsAvailable;
    this.productsNotAvailable = productsNotAvailable;
  }
}
