import { ConfigCatWebProvider } from "@openfeature/config-cat-web-provider";
import { Provider } from "@openfeature/react-sdk";
import { LogLevel, OptionsForPollingMode, PollingMode, createConsoleLogger } from "configcat-js";
import { FEATURE_FLAG_API_KEY } from "../../common/env";
import { IFeatureFlagProviderFactory } from "../../interfaces/feature-flag-provider-factory.interface";
import { ConfigCatProviderCachedDatasource } from "../ConfigCatProviderCachedDatasource/config-cat-provider-cached-datasource";
import { GTM } from "../gtmEvent/GTMInterfaces";
import { sendGTMEvent } from "../gtmEvents";

export const DEFAULT_CONFIG_CAT_GTM_EVENT_NAME = "experience_impression";

export class ConfigCatProviderFactory implements IFeatureFlagProviderFactory {
  private _FEATURE_FLAG_API_KEY = FEATURE_FLAG_API_KEY;

  constructor(private readonly _featureFlagCache = new ConfigCatProviderCachedDatasource()) {}

  create(): Provider {
    const apiKey = this._getApiKey();
    const options = this._getProviderConfig();
    const provider = ConfigCatWebProvider.create(apiKey, options);

    return provider;
  }

  private _getProviderConfig(): OptionsForPollingMode<PollingMode.LazyLoad> {
    const logger = createConsoleLogger(LogLevel.Warn);

    return {
      logger,
      setupHooks: (hooks: any) => {
        hooks.on("clientReady", () => console.debug("ConfigCat Client is ready!"));

        hooks.on("flagEvaluated", (evaluationDetails: any) => {
          const { key, value } = evaluationDetails;

          const cachedKeyValue = this._featureFlagCache.get(key);
          const isKeyValueUnchanged = cachedKeyValue === value;

          if (isKeyValueUnchanged) {
            console.debug(`Flag "${key}" retrieved from cache with value: ${this._featureFlagCache.get(key)}`);
            return;
          }

          this._featureFlagCache.set(key, value);

          const eventData: GTM.Event = {
            event: DEFAULT_CONFIG_CAT_GTM_EVENT_NAME,
            feature_flag_name: key,
            feature_flag_value: value,
          }
          sendGTMEvent(eventData);
        });
      },
    };
  }

  private _getApiKey() {
    return this._FEATURE_FLAG_API_KEY;
  }
}
