import { Ability } from "@casl/ability";
import { AxiosResponse } from "axios";
import { ABILITY_RULES_KEY, BONUSX_ACCESS_TOKEN, ordinaryForms, ROOT_STORE_STORAGE_INDEX } from "../../common/env";
import { productsService } from "../../pages/ProductsListPage/stores/products.store.service";
import { citizenApi } from "../../services/citizen-api-instance";
import { MainSteps } from "../../services/MainSteps";
import { saveQuestionnaireDataToApi } from "../../store";
import { getCookie } from "../../utils/getCookie";
import { doLogout } from "../../utils/logout";
import { globalStickyError$ } from "../globalStickyError";
import { showSavingsIcon$ } from "../showSavingsIcon";

function saveQuestionnaireLocally(prevState: RootStoreModule.State): RootStoreModule.State {
  const activeSteps = MainSteps.get();

  let newState = { ...prevState, activeStepName: activeSteps.currentForm, currentForm: activeSteps.currentForm };

  if (ordinaryForms.includes(prevState.currentForm)) {
    MainSteps.set({ ...prevState });
  }

  localStorage.setItem(prevState?.keySaveState || ROOT_STORE_STORAGE_INDEX, JSON.stringify(newState));

  return newState;
}

async function questionnaireIsValid(prevState: RootStoreModule.State): Promise<RootStoreModule.State> {
  const { formValues, currentForm } = { ...prevState };

  const accessToken = getCookie(BONUSX_ACCESS_TOKEN);
  if (!accessToken) {
    return Promise.reject("Login required");
  }

  const rules = JSON.parse(localStorage.getItem(ABILITY_RULES_KEY) || "[]");
  const abilityRules = new Ability(rules);
  const canUpdateAccount = abilityRules.cannot("update", "Account");
  if (canUpdateAccount) {
    return Promise.reject("Insufficient permission");
  }

  const keysFormValues = Object.keys(formValues) || [];

  const isEmptyForms = !Math.min(keysFormValues.length, currentForm?.length);
  if (isEmptyForms) {
    return Promise.reject("Form not valid");
  }

  return Promise.resolve(prevState);
}

async function persistQuestionnaireRemotely(prevState: RootStoreModule.State): Promise<AxiosResponse<void>> {
  const { formValues, stepHistory } = { ...prevState };

  const activeSteps = MainSteps.get();
  const updateQuestionnaire = await citizenApi.updateQuestionnaireFields({
    questionnaire: {
      formValues,
      stepHistory,
      currentForm: activeSteps.currentForm || "welcome",
      activeStepName: activeSteps.activeStepName,
    },
  });

  return updateQuestionnaire;
}

export async function handleQuestionnaire(data: RootStoreModule.State): Promise<unknown[] | void> {
  const isValidQuestionnaire = await questionnaireIsValid(data);
  if (!isValidQuestionnaire) {
    console.error("Questionnaire is not valid", data);
    return;
  }

  showSavingsIcon$.next(true);

  saveQuestionnaireLocally(data);

  const actions: unknown[] = [persistQuestionnaireRemotely(data), saveQuestionnaireDataToApi(data)];

  let response = await Promise.all(actions)
    .catch(function handle401(err) {
      const unauthorizedStatus = 401;
      const persistQuestionnaireErr = err[0]?.response?.status;
      const isUnauthorized = persistQuestionnaireErr === unauthorizedStatus;

      if (isUnauthorized) {
        doLogout().finally(() => {
          globalStickyError$.next({ message: "Sessione scaduta" });
          window.location.href = "/welcome";
        });
        throw err;
      }
    })
    .finally(function delayedHideIcon() {
      productsService.resetProductsWithAvailability();

      setTimeout(function hideIcon() {
        showSavingsIcon$.next(false);
      }, 500);
    });

  return response;
}
