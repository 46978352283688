import { selectAtom } from "jotai/utils";
import omit from "lodash/omit";
import { RootStore } from "./app";

export const FormValuesStore = selectAtom(
  RootStore,
  (store) => store.updates.formValues as RootStoreModule.State["formValues"]
);

export const FormValuesWithoutStepStore = selectAtom(FormValuesStore, (formValues) => {
  const formValuesWithoutStep = omit(formValues, ["currentForm", "activeStepName"]);
  return formValuesWithoutStep;
});
