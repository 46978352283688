import { Mapper } from "../../../../utils/mapper";
import { ConversationRemoteDataSource } from "../../datasources/conversations/conversation-remote.datasource";
import { IConversationRemoteDataSource } from "../../datasources/conversations/conversation-remote.datasource.interface";
import { SessionDTO } from "../../dtos/session.dto";
import { ChatbotMessage, IChatbotMessage } from "../../entities/chatbot-message.entity";
import { Conversation, IConversation } from "../../entities/conversation.entity";
import { IConversationRepository } from "./conversation.repository.interface";

export class ConversationRepository implements IConversationRepository {
  constructor(private readonly _remoteDataSource: IConversationRemoteDataSource = new ConversationRemoteDataSource()) {}
  public createNewSession(): Promise<SessionDTO> {
    return this._remoteDataSource.createNewSession();
  }

  public getLastSession(): Promise<SessionDTO> {
    return this._remoteDataSource.getLastSession();
  }

  public async getAllMessagesForSession(sessionId: string): Promise<IChatbotMessage[]> {
    const sessionMessages = await this._remoteDataSource.getAllMessagesForSession(sessionId);

    return Mapper.mapArrayData(ChatbotMessage, sessionMessages);
  }

  public async getAllConversations(): Promise<IConversation[]> {
    const conversations = await this._remoteDataSource.getAllConversations();
    return Mapper.mapArrayData(Conversation, conversations);
  }

  public async cloneFromPreviousChat(): Promise<SessionDTO> {
    return this._remoteDataSource.cloneFromPreviousChat();
  }
}
