import { createContext } from "react";
import { chatbotInitialState } from "./lib/reducer/chatbot.reducer";
import { ChatbotAction, ChatbotState } from "./lib/reducer/chatbot.reducer.type";

export interface IChatbotContext {
  state: ChatbotState;
  dispatch: React.Dispatch<ChatbotAction>;
}

export const ChatbotContext = createContext<IChatbotContext>({
  state: chatbotInitialState,
  dispatch: () => {},
});
