import lru from "quick-lru";
import { ProductsAvailabilitiesDto } from "../dtos/products-availabilities.dto";
import { IProductsAvailabilitiesDto } from "../dtos/products-availabilities.dto.interface";
import { IProductsCacheDatasource } from "./products-cache.datasource.interface";

const CACHE_MAX_AGE_MILLIS = 60_000;
const CACHE_MAX_SIZE = 1;

export class ProductsCacheDatasource implements IProductsCacheDatasource {
  private readonly _CACHE_KEY = "products-list";
  constructor(
    private readonly _cache = new lru<string, IProductsAvailabilitiesDto>({
      maxSize: CACHE_MAX_SIZE,
      maxAge: CACHE_MAX_AGE_MILLIS,
    })
  ) {}

  resetCache(): void {
    this._cache.delete(this._CACHE_KEY);
  }

  getAllProducts(): IProductsAvailabilitiesDto | undefined {
    const data = this._getCache();

    if (!data) {
      return undefined;
    }

    const availabilities = new ProductsAvailabilitiesDto(data);
    return availabilities;
  }

  setAllProducts(data: IProductsAvailabilitiesDto): void {
    this._cache.set(this._CACHE_KEY, data);
  }

  getProductsByUniqueIdentifiers(uniqueIdentifiers: string[]): IProductsAvailabilitiesDto | undefined {
    const productsCached = this._getCache();
    if (!productsCached) {
      return undefined;
    }

    const filteredAvailableProducts = productsCached.available.filter(function filterUniqueIdentifiers(
      product
    ): boolean {
      return uniqueIdentifiers.includes(product.uniqueIdentifier);
    });

    const filteredNotAvailableProducts = productsCached.notAvailable.filter(function filterUniqueIdentifiers(
      product
    ): boolean {
      return uniqueIdentifiers.includes(product.uniqueIdentifier);
    });

    const data: IProductsAvailabilitiesDto = {
      available: filteredAvailableProducts,
      notAvailable: filteredNotAvailableProducts,
    };

    const availabilities = new ProductsAvailabilitiesDto(data);
    return availabilities;
  }

  private _getCache() {
    return this._cache.get(this._CACHE_KEY);
  }
}
