import { IProductDto } from "./product.dto.interface";
import { IProductsAvailabilitiesDto } from "./products-availabilities.dto.interface";

export class ProductsAvailabilitiesDto implements IProductsAvailabilitiesDto {
  available: IProductDto[];
  notAvailable: IProductDto[];

  constructor(data: IProductsAvailabilitiesDto) {
    this.available = data.available;
    this.notAvailable = data.notAvailable;
  }
}
